import altai from "../img/card/altai.png";
import baikal from "../img/card/baikal.png";
import bolotov from "../img/card/bolotov.png";
import charskiepesky from "../img/card/charskiepesky.png";
import derbent from "../img/card/derbent.png";
import divnogorie from "../img/card/divnogorie.png";
import ergaki from "../img/card/ergaki.png";
import kaliningrad from "../img/card/kaliningrad.png";
import kazan from "../img/card/kazan.png";
import kislovodsk from "../img/card/kislovodsk.png";
import kolomna from "../img/card/kolomna.png";
import moskva from "../img/card/moskva.png";
import nikola from "../img/card/nikola.png";
import oranienbaum from "../img/card/oranienbaum.png";
import pokrovann from "../img/card/pokrovann.png";
import pushkin from "../img/card/pushkin.png";
import rostovnd from "../img/card/rostovnd.png";
import seliger from "../img/card/seliger.png";
import shira from "../img/card/shira.png";
import sochi from "../img/card/sochi.png";
import spb from "../img/card/spb.png";
import suzdal from "../img/card/suzdal.png";
import svetlogorsk from "../img/card/svetlogorsk.png";
import vladimir from "../img/card/vladimir.png";
import vnovgorod from "../img/card/vnovgorod.png";
import yapolyana from "../img/card/yapolyana.png";
import yaroslavl from "../img/card/yaroslavl.png";

import citiesJson from "./cities.json";

type Filters =
  | "calm"
  | "active"
  | "culture"
  | "single"
  | "family"
  | "company"
  | "smallBudget"
  | "averageBudget"
  | "unlimitedBudget";

export const choiceCard = (data: Array<Filters>) => {
  const cities: Array<{
    name: string;
    img: string;
    description: string;
    how: string;
    tags: Array<Array<Filters>>;
  }> = [
      {
        name: "Селигер",
        img: seliger,
        description: citiesJson.seliger,
        how: citiesJson.seligerHow,
        tags: [
          ["calm", "single", "smallBudget"],
          ["calm", "company", "smallBudget"],
          ["calm", "family", "smallBudget"],
          ["active", "family", "smallBudget"]
        ],
      },
      {
        name: "Шира",
        img: shira,
        description: citiesJson.shira,
        how: citiesJson.shiraHow,
        tags: [
          ["calm", "single", "smallBudget"],
          ["calm", "company", "smallBudget"],
          ["calm", "family", "smallBudget"],
        ],
      },
      {
        name: "Кисловодск",
        img: kislovodsk,
        description: citiesJson.kislovodsk,
        how: citiesJson.kislovodskHow,
        tags: [
          ["calm", "single", "smallBudget"],
          ["calm", "single", "averageBudget"],
          ["calm", "company", "averageBudget"],
          ["calm", "family", "averageBudget"],
          ["active", "family", "averageBudget"],
        ],
      },
      {
        name: "Светлогорск",
        img: svetlogorsk,
        description: citiesJson.svetlogorsk,
        how: citiesJson.svetlogorskHow,
        tags: [
          ["calm", "single", "averageBudget"],
          ["calm", "single", "unlimitedBudget"],
          ["calm", "company", "averageBudget"],
          ["calm", "company", "unlimitedBudget"],
          ["calm", "family", "averageBudget"],
          ["calm", "family", "unlimitedBudget"],
          ["active", "single", "unlimitedBudget"],
          ["active", "company", "unlimitedBudget"],
          ["active", "family", "averageBudget"],
          ["active", "family", "unlimitedBudget"],
        ],
      },
      {
        name: "Сочи",
        img: sochi,
        description: citiesJson.sochi,
        how: citiesJson.sochiHow,
        tags: [
          ["calm", "single", "averageBudget"],
          ["calm", "company", "smallBudget"],
          ["calm", "company", "averageBudget"],
          ["calm", "family", "smallBudget"],
          ["calm", "family", "averageBudget"],
        ],
      },
      {
        name: "Болотов дача",
        img: bolotov,
        description: citiesJson.bolotov,
        how: citiesJson.bolotovHow,
        tags: [
          ["calm", "single", "unlimitedBudget"],
          ["calm", "company", "unlimitedBudget"],
          ["calm", "family", "unlimitedBudget"],
        ],
      },
      {
        name: "Алтайский край",
        img: altai,
        description: citiesJson.altai,
        how: citiesJson.altaiHow,
        tags: [
          ["calm", "single", "unlimitedBudget"],
          ["calm", "company", "unlimitedBudget"],
          ["calm", "family", "unlimitedBudget"],
          ["active", "single", "averageBudget"],
          ["active", "single", "unlimitedBudget"],
          ["active", "company", "averageBudget"],
          ["active", "company", "unlimitedBudget"],
          ["active", "family", "averageBudget"],
          ["active", "family", "unlimitedBudget"],
        ],
      },
      {
        name: "Дербент",
        img: derbent,
        description: citiesJson.derbent,
        how: citiesJson.derbentHow,
        tags: [
          ["active", "single", "smallBudget"],
          ["active", "company", "smallBudget"],
        ],
      },
      {
        name: "Музей-заповедник «Дивногорье»",
        img: divnogorie,
        description: citiesJson.divnogorie,
        how: citiesJson.divnogorieHow,
        tags: [
          ["active", "single", "smallBudget"],
          ["active", "company", "smallBudget"],
          ["active", "family", "smallBudget"],
          ["culture", "single", "smallBudget"],
        ],
      },
      {
        name: "Никола-Ленивец",
        img: nikola,
        description: citiesJson.nikola,
        how: citiesJson.nikolaHow,
        tags: [
          ["active", "single", "smallBudget"],
          ["active", "company", "smallBudget"],
          ["active", "family", "smallBudget"],
        ],
      },
      {
        name: "Ергаки",
        img: ergaki,
        description: citiesJson.ergaki,
        how: citiesJson.ergakiHow,
        tags: [
          ["active", "single", "averageBudget"],
          ["active", "company", "averageBudget"],
        ],
      },
      {
        name: "Чарские пески",
        img: charskiepesky,
        description: citiesJson.charskiepesky,
        how: citiesJson.charskiepeskyHow,
        tags: [
          ["active", "single", "averageBudget"],
          ["active", "company", "averageBudget"],
        ],
      },
      {
        name: "Берег Байкала",
        img: baikal,
        description: citiesJson.baikal,
        how: citiesJson.baikalHow,
        tags: [
          ["active", "single", "unlimitedBudget"],
          ["active", "company", "unlimitedBudget"],
          ["active", "family", "unlimitedBudget"],
        ],
      },
      {
        name: "Ораниенбаум",
        img: oranienbaum,
        description: citiesJson.oranienbaum,
        how: citiesJson.oranienbaumHow,
        tags: [["culture", "single", "smallBudget"]],
      },
      {
        name: "Покрова-на-Нерли",
        img: pokrovann,
        description: citiesJson.pokrovann,
        how: citiesJson.pokrovannHow,
        tags: [["culture", "single", "smallBudget"]],
      },
      {
        name: "Великий Новгород",
        img: vnovgorod,
        description: citiesJson.vnovgorod,
        how: citiesJson.vnovgorodHow,
        tags: [["culture", "single", "averageBudget"]],
      },
      {
        name: "Ярославль",
        img: yaroslavl,
        description: citiesJson.yaroslavl,
        how: citiesJson.yaroslavlHow,
        tags: [
          ["culture", "single", "averageBudget"],
          ["culture", "family", "averageBudget"],
        ],
      },
      {
        name: "Калининград",
        img: kaliningrad,
        description: citiesJson.kaliningrad,
        how: citiesJson.kaliningradHow,
        tags: [
          ["culture", "single", "averageBudget"],
          ["culture", "family", "averageBudget"],
        ],
      },
      {
        name: "Санкт-Петербург",
        img: spb,
        description: citiesJson.spb,
        how: citiesJson.spbHow,
        tags: [
          ["culture", "single", "unlimitedBudget"],
          ["culture", "company", "unlimitedBudget"],
          ["culture", "family", "unlimitedBudget"],
        ],
      },
      {
        name: "Казань",
        img: kazan,
        description: citiesJson.kazan,
        how: citiesJson.kazanHow,
        tags: [
          ["culture", "single", "unlimitedBudget"],
          ["culture", "company", "unlimitedBudget"],
          ["culture", "family", "unlimitedBudget"],
        ],
      },
      {
        name: "Москва",
        img: moskva,
        description: citiesJson.moskva,
        how: citiesJson.moskvaHow,
        tags: [
          ["culture", "single", "unlimitedBudget"],
          ["culture", "company", "unlimitedBudget"],
          ["culture", "family", "unlimitedBudget"],
        ],
      },
      {
        name: "Ясная поляна",
        img: yapolyana,
        description: citiesJson.yapolyana,
        how: citiesJson.yapolyanaHow,
        tags: [
          ["culture", "company", "smallBudget"],
          ["culture", "family", "smallBudget"],
        ],
      },
      {
        name: "Ростов-на-Дону",
        img: rostovnd,
        description: citiesJson.rostovnd,
        how: citiesJson.rostovndHow,
        tags: [["culture", "company", "averageBudget"]],
      },
      {
        name: "Суздаль",
        img: suzdal,
        description: citiesJson.suzdal,
        how: citiesJson.suzdalHow,
        tags: [["culture", "company", "averageBudget"]],
      },
      {
        name: "Пушкин",
        img: pushkin,
        description: citiesJson.pushkin,
        how: citiesJson.pushkinHow,
        tags: [
          ["culture", "company", "smallBudget"],
          ["culture", "family", "smallBudget"],
        ],
      },
      {
        name: "Коломна",
        img: kolomna,
        description: citiesJson.kolomna,
        how: citiesJson.kolomnaHow,
        tags: [
          ["culture", "company", "smallBudget"],
          ["culture", "family", "smallBudget"],
        ],
      },
      {
        name: "Владимир",
        img: vladimir,
        description: citiesJson.vladimir,
        how: citiesJson.vladimirHow,
        tags: [
          ["culture", "company", "averageBudget"],
          ["culture", "family", "averageBudget"],
        ],
      },
    ];

  return cities.filter((elem) => elem.tags.some(e => e.every(v => data.includes(v)))
  );
};
