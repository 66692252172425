import { atom } from '@mntm/precoil'
import {INotes, readingInfoTypes} from '../types/book'

export const atomBookAnimation = atom(0)
export const atomNotes = atom<INotes>({isLoaded: false, data: []}, 'notes')
export const atomReadingInfo = atom<readingInfoTypes>({} as readingInfoTypes, 'readingInfo')


export const setAtomNotes = atomNotes.set
export const setReadingInfo = atomReadingInfo.set
export const setBookAnimation = atomBookAnimation.set
