import {FC, HTMLAttributes, ReactNode, useContext} from 'react'
import {LevelContext} from "../../../pages/MerchandiseGame/GameLevels/context";
import {ThirdItemSelected} from "./third-item-selected";

interface BaseItemProps extends HTMLAttributes<HTMLDivElement> {
    index: number
    children: ReactNode
    selectedItem?: ReactNode
}

export const BaseItem: FC<BaseItemProps> = (props) => {
    const { index, children, selectedItem = <ThirdItemSelected index={index} />, className } = props
    const { selectedItems, setSelectedItems } = useContext(LevelContext)


    const onItemClick = (id: number) => () => setSelectedItems(prev => {
        console.log(id, '- индекс предмета')
        if (prev.includes(id)) return prev.filter(index => index !== (id))

        return [...prev, (id)]
    })


    if (selectedItems.includes(index)) return <div onClick={onItemClick(index)}>{ selectedItem }</div>


    return (
        <div
            onClick={onItemClick(index)}
            className={className}
        >
            { children }
        </div>
    )
}