let timeout: ReturnType<typeof setTimeout> | null = null;
export const useDebounce = () => {
    return {
        debounce: <T extends Function>(value: T, delay: number) => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                value.apply(null);
            }, delay);
        },
    };
};

