import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PageBase, Heading, Typography, Button } from "../../components";
import { Logo } from "../../img/svg/logo";
import styles from './HomePage.module.css';
import bridge from "@vkontakte/vk-bridge";

export const HomePage: FC = () => {
    const navigation = useNavigate();

    const handleClick = () => {
        bridge.send("VKWebAppAllowNotifications");
        navigation('/choice');
    }

    return <PageBase>
        <div className={styles['Page__content']}>
            <div className={styles['Background']}>
                <div className={styles['Text-block']}>
                    <Logo />
                    <Heading style={{
                        textAlign: 'center',
                        marginTop: 34,
                        marginBottom: 27
                    }}>От Калининграда до Владивостока</Heading>
                    <Typography style={{ textAlign: 'center', marginBottom: 47 }}>
                        В России есть всё: и моря с песчаными пляжами,
                        и горы с минеральными водами, и даже города с европейской
                        архитектурой и русской душой. Нужно только выбрать,
                        куда отправиться.
                    </Typography>
                    <Button size="m" onClick={handleClick}>Подобрать путешествие</Button>
                </div>
            </div>
        </div>
    </PageBase>
}