import { FC } from 'react'
import Modal from 'react-modal'
import {useAtomValue} from "@mntm/precoil";
import {atomModal, closeModal} from "../store/atoms/modal";

export * from './Hint'
export * from './GameFinish'
export * from './Share'

export const ModalRoot: FC = () => {
    const { isOpen, children } = useAtomValue(atomModal)

    return (
        <Modal
            shouldFocusAfterRender={false}
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={closeModal}
            className="Modal"
            overlayClassName="Modal_overlay"
        >
            { children }
        </Modal>
    )
}