import React, {FC} from 'react'
import lottie from '../../img/lottie/scroll-arrow.json'
import Lottie from 'lottie-react'
import classes from './ScrollHint.module.css'

type props = {
    align: 'horizontal' | 'vertical'
}

export const ScrollHint: FC<props> = ({align}) => {
    const isDesktop = window.innerWidth > 900
    const isVertical = align === 'vertical'

    if (isDesktop) return null

    return (
        <div className={classes.Hint}>
            {
                isVertical
                    ?
                    <>
                        <Lottie
                            width={30}
                            height={70}
                            className={classes.Hint_Vertical}
                            animationData={lottie}
                            loop
                        />
                        <span className={classes.Hint_Placeholder}>
                            Листайте вниз, чтобы читать дальше
                        </span>
                    </>
                    :
                    <Lottie
                        width={30}
                        height={70}
                        className={classes.Hint_Horizontal}
                        animationData={lottie}
                        loop
                    />
            }
        </div>
    )
}