import { FC } from 'react'
import styles from './GameResultPage.module.css'
import {useAtomValue} from "@mntm/precoil";
import {
    atomMerchandiseGameInfoDefaultState,
    atomMerchandiseGameResult,
    setAtomMerchandiseGameInfo,
    setAtomMerchandiseGameResult
} from "../../../store/atoms/merchandiseGame";
import {RetryIcon} from "../../../img/svg/retryIcon";
import {Button, GameBasePage} from "../../../components";
import {declination} from "../../../utils/text";
import {setModal} from "../../../store/atoms/modal";
import {ShareModal} from "../../../modals";
import {useNavigate} from "react-router-dom";
import {RoutesPath} from "../../../App";
import {GameResultShareIcon} from "../../../img/svg/game-result-share-icon";
import shareIcon from '../../../img/png/share-icon.png'
import {ShareIcon} from "../../../img/svg/shareIcon";

export const GameResultPage: FC = () => {
    const navigate = useNavigate()
    const { points } = useAtomValue(atomMerchandiseGameResult)
    const minThreshold = 50
    const isWin = points >= minThreshold

    const openShareModal = () => setModal(<ShareModal />)
    const goToMainMenu = () => navigate(`../${RoutesPath.MAIN_PAGE}`)

    const retryGame = () => {
        setAtomMerchandiseGameResult({ points: 0 })
        setAtomMerchandiseGameInfo({ ...atomMerchandiseGameInfoDefaultState })
        navigate(`../${RoutesPath.MERCHANDISE_LEVELS_PAGE}`, { replace: true })
    }

    return (
        <GameBasePage
            className={styles['Result_Page']}
            style={{ background: '#fff' }}
        >
            <div className={styles['Page_Content']}>

                <div className={styles['Points_Title']}>
                    <h1>Вы набрали <br /> { points } { declination(points, ['балл', 'балла', 'баллов']) }</h1>
                </div>
               {/*<div>*/}
               {/*    <ResultStars />*/}
               {/*</div>*/}

               <div className={styles['Page_Text']}>
                   <h1
                       dangerouslySetInnerHTML={{__html: isWin
                               ? 'Вашей внимательности можно позавидовать!'
                               : `Хороший результат, <br /> но нужно потренировать внимательность.`
                       }}
                   />
                   <h2
                       dangerouslySetInnerHTML={{__html: isWin
                               ? `Приглашаем поучаствовать в розыгрыше призов от РЖД.\n
Всё просто:
— Нужно быть подписанным на сообщество РЖД
— Необходимо сделать репост этой <a href="https://vk.com/rzd_official" target="_blank">записи</a> из группы РЖД ВКонтакте на свою стену`
                               : 'Попробуйте снова пройти игру, чтобы поучаствовать в розыгрыше призов от РЖД'
                       }}
                   />
               </div>
           </div>

            <div className={styles['Page_Actions']}>
                <div className={styles['Page_IconButtons']}>
                    <Button
                        size="s"
                        mode="gray"
                        onClick={retryGame}
                    >
                        <RetryIcon />
                    </Button>
                    <Button
                        size="s"
                        mode="gray"
                        onClick={openShareModal}
                    >
                        <ShareIcon />
                    </Button>
                </div>

                <div className={styles['Page_Stretched_Buttons']}>
                    <a href="https://vk.com/rzd_official" target="_blank">
                        <Button
                            size="l"
                            mode="gray"
                        >
                            купить мерч
                        </Button>
                    </a>
                    <Button
                        size="l"
                        mode="gray"
                        onClick={goToMainMenu}
                    >
                        Вернуться в меню
                    </Button>
                </div>
            </div>
        </GameBasePage>
    )
}