import React, {FC, useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import {BaseLevelPage} from "../../BaseLevelPage";
import styles from "./FifthLevelPage.module.css";
import {FifthItemDefault} from "../../../../img/svg/merchandise-levels/fifth-level/item-default";
import {
    FifthItemWithWrongKernelColor
} from "../../../../img/svg/merchandise-levels/fifth-level/item-with-wrong-kernel-color";
import {
    FifthItemWithWrongSignColor
} from "../../../../img/svg/merchandise-levels/fifth-level/item-with-wrong-sign-color";
import {FifthItemWithoutSign} from "../../../../img/svg/merchandise-levels/fifth-level/item-without-sign";
import {FifthItemWithWrongText} from "../../../../img/svg/merchandise-levels/fifth-level/item-with-wrong-text";
import {FifthItemWithLongHood} from "../../../../img/svg/merchandise-levels/fifth-level/item-with-long-hood";
import {
    FifthItemWithWrongHoodColor
} from "../../../../img/svg/merchandise-levels/fifth-level/item-with-wrong-hood-color";

export const FifthLevelPage: FC = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1)

    return (
        <BaseLevelPage
            className={styles['Level_Page']}
            nextModalTitle="Переходим к результатам?"
            nextModalButtonsText={['Да, хочу узнать баллы', 'Нет, поищу ещё']}
            level={5}
            onLeftArrowClick={goBack}
        >
            <div className={styles['Pen_Lines']}>
                <div className={styles['Pen_Line1']}>
                    {
                        new Array(9)
                            .fill('')
                            .map((_, key) => (
                                <div>
                                    <FifthItemDefault index={key + 1} />
                                </div>
                            ))
                    }
                </div>
                <div className={styles['Pen_Line2']}>
                    <div><FifthItemDefault index={11} /></div>
                    <div><FifthItemDefault index={12} /></div>
                    <div><FifthItemDefault index={13} /></div>
                    <div><FifthItemDefault index={14} /></div>
                    <div><FifthItemWithWrongKernelColor index={15} /></div>
                    <div><FifthItemWithWrongSignColor index={16} /></div>
                    <div><FifthItemDefault index={17} /></div>
                    <div><FifthItemDefault index={18} /></div>
                    <div><FifthItemDefault index={19} /></div>
                    <div><FifthItemDefault index={20} /></div>
                </div>
                <div className={styles['Pen_Line2']}>

                    <div><FifthItemDefault index={21} /></div>
                    <div><FifthItemDefault index={22} /></div>
                    <div><FifthItemDefault index={23} /></div>
                    <div><FifthItemWithoutSign index={24} /></div>
                    <div><FifthItemDefault index={25} /></div>
                    <div><FifthItemDefault index={26} /></div>
                    <div><FifthItemWithWrongText index={27} /></div>
                    <div><FifthItemDefault index={28} /></div>
                    <div><FifthItemDefault index={29} /></div>
                    <div><FifthItemDefault index={30} /></div>
                </div>
                <div className={styles['Pen_Line2']}>
                    <div><FifthItemDefault index={30} /></div>
                    <div><FifthItemDefault index={31} /></div>
                    <div><FifthItemDefault index={32} /></div>
                    <div><FifthItemDefault index={33} /></div>
                    <div><FifthItemWithLongHood index={34} /></div>
                    <div><FifthItemDefault index={35} /></div>
                    <div><FifthItemWithWrongHoodColor index={36} /></div>
                    <div><FifthItemDefault index={37} /></div>
                    <div><FifthItemDefault index={38} /></div>
                    <div><FifthItemDefault index={39} /></div>
                    <div><FifthItemDefault index={40} /></div>
                </div>

                <div className={styles['Pen_Line2']}>
                    {
                        new Array(10)
                            .fill('')
                            .map((_, key) => (
                                <div>
                                    <FifthItemDefault index={(key + 40) + 1} />
                                </div>
                            ))
                    }
                </div>

                <div className={styles['Pen_Line2']}>
                    {
                        new Array(10)
                            .fill('')
                            .map((_, key) => (
                                <div>
                                    <FifthItemDefault index={(key + 51) + 1} />
                                </div>
                            ))
                    }
                </div>
            </div>
        </BaseLevelPage>
    )
}