import { FC } from 'react'

export const ThirdItemWithWrongHandle: FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="129"
        height="184"
        fill="none"
        viewBox="0 0 129 184"
    >
        <mask id="path-1-inside-1_442_67533" fill="#fff">
            <path d="M52.052 182C20.93 182 0 177.394 0 173.093v-4.616l6.988.248h.033c9.385-2.811 26.634-4.538 45.031-4.538 18.398 0 35.647 1.727 45.031 4.538h.034l6.988-.334v4.702c.033 4.301-20.896 8.907-52.053 8.907z"></path>
        </mask>
        <path
            fill="#57565B"
            stroke="#333"
            strokeWidth="2"
            d="M52.052 182C20.93 182 0 177.394 0 173.093v-4.616l6.988.248h.033c9.385-2.811 26.634-4.538 45.031-4.538 18.398 0 35.647 1.727 45.031 4.538h.034l6.988-.334v4.702c.033 4.301-20.896 8.907-52.053 8.907z"
            mask="url(#path-1-inside-1_442_67533)"
        ></path>
        <mask id="path-2-inside-2_442_67533" fill="#fff">
            <path d="M52.052 177.665c-37.064 0-52.052-6.57-52.052-9.313 0-.237.27-.508.473-.644l.033-.033.034-.034c4.355-5.859 7.593-13.939 8.134-15.327h86.834c2.295 8.263 7.82 15.157 7.888 15.225l.033.034.034.034c.304.203.642.474.642.779.033 2.709-14.955 9.279-52.053 9.279z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M52.052 177.665c-37.064 0-52.052-6.57-52.052-9.313 0-.237.27-.508.473-.644l.033-.033.034-.034c4.355-5.859 7.593-13.939 8.134-15.327h86.834c2.295 8.263 7.82 15.157 7.888 15.225l.033.034.034.034c.304.203.642.474.642.779.033 2.709-14.955 9.279-52.053 9.279z"
            mask="url(#path-2-inside-2_442_67533)"
        ></path>
        <g filter="url(#filter0_f_442_67533)">
            <path
                fill="#999"
                d="M39.9 158.87c-.168.101-.168.372.034.44.608.237.777.982.371 1.49-.607.677-1.114 1.456-1.316 2.337-.405 1.862.608 3.86-.034 5.655-.304.813-.911 1.457-1.283 2.235-.472.949-.438 2.337.844 2.608.27.068.54.203.71.474.506.881-.507 1.287-1.25 1.321a60.841 60.841 0 01-19.781-1.863c-.574-.135-.439-1.016.169-.948.54.068 1.316.034 1.418-.508.135-.576-.642-1.016-.676-1.591-.033-.475.405-.881.878-1.05.473-.17.979-.17 1.418-.373.945-.44 1.316-1.592 1.586-2.608l1.418-5.486c.236-.948.439-2.235 1.148-2.98.911-.88 2.363-.576 3.51-.508 1.925.102 3.849.203 5.74.339 1.046.067 2.092.135 3.139.169.405.034 1.147.169 1.822.237.304.068.405.474.135.61z"
            ></path>
        </g>
        <mask id="path-4-inside-3_442_67533" fill="#fff">
            <path d="M52.052 157.075c-11.68 0-22.65-.508-30.921-1.49-12.186-1.389-12.524-3.048-12.524-3.218 0-.169.338-1.828 12.524-3.217 8.27-.948 19.241-1.49 30.92-1.49 11.68 0 22.651.508 30.922 1.49 12.186 1.389 12.523 3.048 12.523 3.217 0 .17-.337 1.829-12.523 3.218-8.237.948-19.242 1.49-30.921 1.49z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M52.052 157.075c-11.68 0-22.65-.508-30.921-1.49-12.186-1.389-12.524-3.048-12.524-3.218 0-.169.338-1.828 12.524-3.217 8.27-.948 19.241-1.49 30.92-1.49 11.68 0 22.651.508 30.922 1.49 12.186 1.389 12.523 3.048 12.523 3.217 0 .17-.337 1.829-12.523 3.218-8.237.948-19.242 1.49-30.921 1.49z"
            mask="url(#path-4-inside-3_442_67533)"
        ></path>
        <path
            stroke="#999"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M3.207 173s5.806 2.438 11.275 3.42c5.468.983 18.026 3.082 38.38 3.082 20.356 0 39.833-2.607 48.002-6.468"
        ></path>
        <mask id="path-6-inside-4_442_67533" fill="#fff">
            <path d="M52.053 155.822c-10.971 0-21.3-.508-29.065-1.389-11.41-1.321-11.747-2.845-11.747-3.014V81.088H92.864v70.331c0 .169-.338 1.693-11.747 3.014-7.764.914-18.06 1.389-29.064 1.389z"></path>
        </mask>
        <path
            fill="#727272"
            stroke="#333"
            strokeWidth="2"
            d="M52.053 155.822c-10.971 0-21.3-.508-29.065-1.389-11.41-1.321-11.747-2.845-11.747-3.014V81.088H92.864v70.331c0 .169-.338 1.693-11.747 3.014-7.764.914-18.06 1.389-29.064 1.389z"
            mask="url(#path-6-inside-4_442_67533)"
        ></path>
        <g filter="url(#filter1_f_442_67533)">
            <path
                fill="#999"
                d="M45.9 89.021c.844.068 2.026-.17 1.992-1.016-.034-.745-.98-.982-1.722-1.05-4.422-.304-8.878-.71-13.333-.88-1.722-.068-5.233-.305-6.414 1.151 1.654 1.524 6.549.915 8.743 1.05 3.612.271 7.156.508 10.734.745z"
            ></path>
        </g>
        <mask id="path-8-inside-5_442_67533" fill="#fff">
            <path d="M52.053 85.483c-10.971 0-21.3-.508-29.065-1.389-11.41-1.32-11.747-2.844-11.747-3.014 0-.17.338-1.693 11.747-3.014 7.764-.88 18.094-1.388 29.065-1.388 10.97 0 21.3.508 29.064 1.388 11.41 1.321 11.747 2.845 11.747 3.014 0 .17-.338 1.694-11.747 3.014-7.764.915-18.06 1.389-29.064 1.389z"></path>
        </mask>
        <path
            fill="#5A5A5A"
            stroke="#333"
            strokeWidth="2"
            d="M52.053 85.483c-10.971 0-21.3-.508-29.065-1.389-11.41-1.32-11.747-2.844-11.747-3.014 0-.17.338-1.693 11.747-3.014 7.764-.88 18.094-1.388 29.065-1.388 10.97 0 21.3.508 29.064 1.388 11.41 1.321 11.747 2.845 11.747 3.014 0 .17-.338 1.694-11.747 3.014-7.764.915-18.06 1.389-29.064 1.389z"
            mask="url(#path-8-inside-5_442_67533)"
        ></path>
        <g filter="url(#filter2_f_442_67533)">
            <path
                fill="#999"
                d="M29.572 132.117c-1.08.277-1.857 1.17-2.261 2.124-.372.954-.44 1.97-.473 2.986l-.405 10.895c-.034 1.17-.034 2.462.844 3.293.843.8 2.16.924 3.375.985 2.295.154 4.556.308 6.85.431 1.047.062 2.228.092 2.97-.554.743-.616.81-1.6.845-2.524.135-3.539.27-7.079.37-10.618.068-1.447.068-2.955-.775-4.186-.81-1.139-2.262-1.816-3.747-2.031-1.383-.216-2.835.154-4.185-.062-1.147-.185-2.16-1.077-3.408-.739z"
            ></path>
        </g>
        <mask id="path-10-inside-6_442_67533" fill="#fff">
            <path d="M52.107 84.375c-10.584 0-20.528-.493-28.011-1.409-10.99-1.338-11.326-2.853-11.326-3.029l.016-5.698h78.642l-.017 5.698c0 .176-.303 1.691-11.326 3.03-7.45.915-17.393 1.408-27.978 1.408z"></path>
        </mask>
        <path
            fill="#727272"
            stroke="#333"
            strokeWidth="2"
            d="M52.107 84.375c-10.584 0-20.528-.493-28.011-1.409-10.99-1.338-11.326-2.853-11.326-3.029l.016-5.698h78.642l-.017 5.698c0 .176-.303 1.691-11.326 3.03-7.45.915-17.393 1.408-27.978 1.408z"
            mask="url(#path-10-inside-6_442_67533)"
        ></path>
        <mask id="path-11-inside-7_442_67533" fill="#fff">
            <path d="M52.09 78.349c-10.58 0-20.52-.457-28-1.305-10.983-1.239-11.32-2.641-11.32-2.804 0-.163.303-1.566 11.32-2.805 7.48-.848 17.42-1.305 28-1.305 10.58 0 20.52.457 28 1.305 10.984 1.24 11.321 2.641 11.321 2.805 0 .163-.303 1.565-11.32 2.804-7.48.848-17.42 1.305-28 1.305z"></path>
        </mask>
        <path
            fill="gray"
            stroke="#333"
            strokeWidth="2"
            d="M52.09 78.349c-10.58 0-20.52-.457-28-1.305-10.983-1.239-11.32-2.641-11.32-2.804 0-.163.303-1.566 11.32-2.805 7.48-.848 17.42-1.305 28-1.305 10.58 0 20.52.457 28 1.305 10.984 1.24 11.321 2.641 11.321 2.805 0 .163-.303 1.565-11.32 2.804-7.48.848-17.42 1.305-28 1.305z"
            mask="url(#path-11-inside-7_442_67533)"
        ></path>
        <mask id="path-12-inside-8_442_67533" fill="#fff">
            <path d="M52 0C30.458 0 13 1.93 13 4.335v69.153c0 2.54 17.458 4.572 39 4.572 21.541 0 39-2.066 39-4.572V4.335C91 1.93 73.54 0 52 0z"></path>
        </mask>
        <path
            fill="#85B9CA"
            fillOpacity="0.3"
            stroke="#E6F0F3"
            strokeOpacity="0.5"
            strokeWidth="2"
            d="M52 0C30.458 0 13 1.93 13 4.335v69.153c0 2.54 17.458 4.572 39 4.572 21.541 0 39-2.066 39-4.572V4.335C91 1.93 73.54 0 52 0z"
            mask="url(#path-12-inside-8_442_67533)"
        ></path>
        <path
            stroke="#E6F0F3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeOpacity="0.5"
            d="M14.97 5.52s1.328 3.15 37.401 3.15 37.128-3.15 37.128-3.15"
        ></path>
        <g filter="url(#filter3_f_442_67533)" opacity="0.19">
            <path
                fill="#fff"
                d="M33.69 9.862s2.752.395 9.426.395v65.9l-9.426-.548V9.862z"
            ></path>
        </g>
        <mask id="path-15-inside-9_442_67533" fill="#fff">
            <path d="M54.348 131.269c-23.36 0-37.402-2.506-41.15-3.285a.402.402 0 01-.337-.406V87.752a.39.39 0 01.405-.407h.068c12.827 1.863 26.364 2.777 40.271 2.777 12.018 0 20.625-.711 23.765-1.016h.033a.39.39 0 01.405.407v40.503c0 .203-.168.406-.37.406-7.9.576-15.664.847-23.09.847z"></path>
        </mask>
        <path
            fill="#727272"
            stroke="#999"
            strokeWidth="4"
            d="M54.348 131.269c-23.36 0-37.402-2.506-41.15-3.285a.402.402 0 01-.337-.406V87.752a.39.39 0 01.405-.407h.068c12.827 1.863 26.364 2.777 40.271 2.777 12.018 0 20.625-.711 23.765-1.016h.033a.39.39 0 01.405.407v40.503c0 .203-.168.406-.37.406-7.9.576-15.664.847-23.09.847z"
            mask="url(#path-15-inside-9_442_67533)"
        ></path>
        <path
            stroke="#999"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.854"
            d="M30.043 129.577V89.855l22.853 40.817V90.676l24.316 38.901M29.973 89.855l-16.384 37.53"
        ></path>
        <path
            fill="#999"
            d="M28.153 89.242l1.89 4.267 2.364-4.132-4.254-.135zM50.938 127.476h1.958v3.59l-1.958-3.59zM52.897 90.326v4.165l2.464-.203-2.464-3.962z"
        ></path>
        <path
            stroke="#666"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M12.996 132.895s4.76 2.81 32.339 3.657c20.625.643 34.397-1.388 34.397-1.388M12.996 140.447s4.76 2.811 32.339 3.657c20.625.644 34.397-1.388 34.397-1.388"
        ></path>
        <mask id="path-23-inside-10_442_67533" fill="#fff">
            <path d="M92.257 147.037c-1.71 0-3.354-.645-4.45-1.87-.71-.806-.645-2.032.129-2.741.806-.71 2.031-.645 2.74.129.775.871 2.355.709 3.387.064 1.483-.902 2.515-2.644 3.386-4.224l.322-.58c1-1.839 2.032-3.709 3.709-5.225 1.709-1.547 3.741-2.386 5.708-3.224 2.257-.936 4.192-1.774 5.288-3.386 1-1.484 1.161-3.548 1.355-5.74.193-2.387.387-4.87 1.677-7.127 1.032-1.806 2.483-2.999 3.902-4.192.838-.71 1.644-1.355 2.289-2.129 2.161-2.483 3.031-6.417 2.387-10.803-1.065-7.19-5.902-13.802-12.287-16.833-2.096-1-3.934-1.419-5.579-1.29-1.806.13-3.418 1.032-4.224 2.322-.774 1.258-.935 2.967-1.064 4.805-.129 1.548-.258 3.128-.774 4.676-1.58 4.772-6.772 7.933-11.577 7.03a1.92 1.92 0 01-1.548-2.258 1.92 1.92 0 012.257-1.547c2.935.548 6.192-1.484 7.16-4.386.354-1.129.483-2.419.58-3.805.161-2.193.354-4.45 1.644-6.514 1.452-2.387 4.16-3.935 7.224-4.16 2.322-.162 4.805.354 7.514 1.644 7.674 3.612 13.221 11.19 14.479 19.768.806 5.514-.387 10.577-3.29 13.899-.838.967-1.805 1.773-2.741 2.547-1.193 1-2.354 1.968-3.031 3.128-.838 1.484-1 3.451-1.193 5.515-.193 2.58-.419 5.256-1.999 7.578-1.742 2.612-4.547 3.773-6.998 4.805-1.741.742-3.386 1.419-4.612 2.515-1.193 1.064-2.031 2.612-2.902 4.225l-.322.612c-1.194 2.129-2.58 4.289-4.74 5.644-1.162.774-2.516 1.128-3.806 1.128z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M92.257 147.037c-1.71 0-3.354-.645-4.45-1.87-.71-.806-.645-2.032.129-2.741.806-.71 2.031-.645 2.74.129.775.871 2.355.709 3.387.064 1.483-.902 2.515-2.644 3.386-4.224l.322-.58c1-1.839 2.032-3.709 3.709-5.225 1.709-1.547 3.741-2.386 5.708-3.224 2.257-.936 4.192-1.774 5.288-3.386 1-1.484 1.161-3.548 1.355-5.74.193-2.387.387-4.87 1.677-7.127 1.032-1.806 2.483-2.999 3.902-4.192.838-.71 1.644-1.355 2.289-2.129 2.161-2.483 3.031-6.417 2.387-10.803-1.065-7.19-5.902-13.802-12.287-16.833-2.096-1-3.934-1.419-5.579-1.29-1.806.13-3.418 1.032-4.224 2.322-.774 1.258-.935 2.967-1.064 4.805-.129 1.548-.258 3.128-.774 4.676-1.58 4.772-6.772 7.933-11.577 7.03a1.92 1.92 0 01-1.548-2.258 1.92 1.92 0 012.257-1.547c2.935.548 6.192-1.484 7.16-4.386.354-1.129.483-2.419.58-3.805.161-2.193.354-4.45 1.644-6.514 1.452-2.387 4.16-3.935 7.224-4.16 2.322-.162 4.805.354 7.514 1.644 7.674 3.612 13.221 11.19 14.479 19.768.806 5.514-.387 10.577-3.29 13.899-.838.967-1.805 1.773-2.741 2.547-1.193 1-2.354 1.968-3.031 3.128-.838 1.484-1 3.451-1.193 5.515-.193 2.58-.419 5.256-1.999 7.578-1.742 2.612-4.547 3.773-6.998 4.805-1.741.742-3.386 1.419-4.612 2.515-1.193 1.064-2.031 2.612-2.902 4.225l-.322.612c-1.194 2.129-2.58 4.289-4.74 5.644-1.162.774-2.516 1.128-3.806 1.128z"
            mask="url(#path-23-inside-10_442_67533)"
        ></path>
        <path
            stroke="#666"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M88.936 94.441c2.773.323 4.16.162 7.158-2.483 2.161-1.902 2.484-7.062 2.484-7.062"
        ></path>
        <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M98.867 82.478c1.29-6.224 5.386-7.192 10.61-6.192 3.934.742 10.383 4.902 13.608 11.061"
        ></path>
        <mask id="path-26-inside-11_442_67533" fill="#fff">
            <path d="M15.63 149.353a1.876 1.876 0 01-1.857-1.862c0-1.016.843-1.863 1.856-1.863 1.013 0 1.857.847 1.857 1.863a1.876 1.876 0 01-1.857 1.862z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M15.63 149.353a1.876 1.876 0 01-1.857-1.862c0-1.016.843-1.863 1.856-1.863 1.013 0 1.857.847 1.857 1.863a1.876 1.876 0 01-1.857 1.862z"
            mask="url(#path-26-inside-11_442_67533)"
        ></path>
        <mask id="path-27-inside-12_442_67533" fill="#fff">
            <path d="M23.089 150.708a1.86 1.86 0 001.857-1.863 1.86 1.86 0 00-1.857-1.863 1.86 1.86 0 00-1.857 1.863 1.86 1.86 0 001.857 1.863z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M23.089 150.708a1.86 1.86 0 001.857-1.863 1.86 1.86 0 00-1.857-1.863 1.86 1.86 0 00-1.857 1.863 1.86 1.86 0 001.857 1.863z"
            mask="url(#path-27-inside-12_442_67533)"
        ></path>
        <mask id="path-28-inside-13_442_67533" fill="#fff">
            <path d="M31.63 151.554a1.876 1.876 0 01-1.857-1.862c0-1.016.844-1.863 1.857-1.863s1.857.847 1.857 1.863-.81 1.862-1.857 1.862z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M31.63 151.554a1.876 1.876 0 01-1.857-1.862c0-1.016.844-1.863 1.857-1.863s1.857.847 1.857 1.863-.81 1.862-1.857 1.862z"
            mask="url(#path-28-inside-13_442_67533)"
        ></path>
        <mask id="path-29-inside-14_442_67533" fill="#fff">
            <path d="M40.474 151.995a1.86 1.86 0 001.856-1.863 1.859 1.859 0 00-1.856-1.862 1.859 1.859 0 00-1.857 1.862 1.86 1.86 0 001.857 1.863z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M40.474 151.995a1.86 1.86 0 001.856-1.863 1.859 1.859 0 00-1.856-1.862 1.859 1.859 0 00-1.857 1.862 1.86 1.86 0 001.857 1.863z"
            mask="url(#path-29-inside-14_442_67533)"
        ></path>
        <mask id="path-30-inside-15_442_67533" fill="#fff">
            <path d="M49.554 152.503a1.86 1.86 0 001.856-1.863 1.86 1.86 0 00-1.856-1.863 1.86 1.86 0 00-1.857 1.863 1.86 1.86 0 001.857 1.863z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M49.554 152.503a1.86 1.86 0 001.856-1.863 1.86 1.86 0 00-1.856-1.863 1.86 1.86 0 00-1.857 1.863 1.86 1.86 0 001.857 1.863z"
            mask="url(#path-30-inside-15_442_67533)"
        ></path>
        <mask id="path-31-inside-16_442_67533" fill="#fff">
            <path d="M58.77 152.198a1.86 1.86 0 001.856-1.863 1.86 1.86 0 00-1.856-1.862 1.86 1.86 0 00-1.857 1.862 1.86 1.86 0 001.857 1.863z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M58.77 152.198a1.86 1.86 0 001.856-1.863 1.86 1.86 0 00-1.856-1.862 1.86 1.86 0 00-1.857 1.862 1.86 1.86 0 001.857 1.863z"
            mask="url(#path-31-inside-16_442_67533)"
        ></path>
        <mask id="path-32-inside-17_442_67533" fill="#fff">
            <path d="M67.782 151.961a1.86 1.86 0 001.857-1.863 1.86 1.86 0 00-1.857-1.863 1.86 1.86 0 00-1.856 1.863 1.86 1.86 0 001.856 1.863z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M67.782 151.961a1.86 1.86 0 001.857-1.863 1.86 1.86 0 00-1.857-1.863 1.86 1.86 0 00-1.856 1.863 1.86 1.86 0 001.856 1.863z"
            mask="url(#path-32-inside-17_442_67533)"
        ></path>
        <mask id="path-33-inside-18_442_67533" fill="#fff">
            <path d="M76.255 151.148a1.876 1.876 0 01-1.857-1.863c0-1.016.844-1.862 1.857-1.862s1.857.846 1.857 1.862-.81 1.863-1.857 1.863z"></path>
        </mask>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="2"
            d="M76.255 151.148a1.876 1.876 0 01-1.857-1.863c0-1.016.844-1.862 1.857-1.862s1.857.846 1.857 1.862-.81 1.863-1.857 1.863z"
            mask="url(#path-33-inside-18_442_67533)"
        ></path>
        <defs>
            <filter
                id="filter0_f_442_67533"
                width="38.697"
                height="34.172"
                x="9.829"
                y="149.409"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_442_67533"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <filter
                id="filter1_f_442_67533"
                width="29.47"
                height="11.014"
                x="22.423"
                y="82.019"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_442_67533"
                    stdDeviation="2"
                ></feGaussianBlur>
            </filter>
            <filter
                id="filter2_f_442_67533"
                width="27.291"
                height="32.82"
                x="20.423"
                y="126.042"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_442_67533"
                    stdDeviation="3"
                ></feGaussianBlur>
            </filter>
            <filter
                id="filter3_f_442_67533"
                width="23.427"
                height="80.295"
                x="26.689"
                y="2.862"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_442_67533"
                    stdDeviation="3.5"
                ></feGaussianBlur>
            </filter>
        </defs>
    </svg>
)