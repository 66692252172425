export function textFormat(text: string): any[] {
    const words = text.split(' ')
    const formattedText = words.map((word, key) => {
        const isLink = word.includes('@') && word.includes('http')
        const isPromo = word.includes('<') && word.includes('>')

        if (isPromo) {
            return <b>{word.replace('<', '').replace('>', '')} </b>
        }

        if (isLink) {
            const text = words[key + 1]
            words[key + 1] = ''

            return <a target="_blank" href={word.slice(1)}>{text.slice(1, text.length - 1)} </a>
        }
        return `${word} `
    })
    return formattedText
}

export const declination = (value: number, words: Array<string>) => {
    value = Math.abs(value) % 100
    const num = (Math.abs(value) % 100) % 10
    if ( value > 10 && value < 20 ) return words[2]
    if ( num > 1 && num < 5 ) return words[1]
    if ( num == 1 ) return words[0]
    return words[2]
}
