import {FC} from 'react'
import {useNavigate} from "react-router-dom";
import {BaseLevelPage} from "../../BaseLevelPage";
import styles from "./FouthLevelPage.module.css";
import {BaseItem} from "../../../../img/svg/merchandise-levels/base-item";
import {FourthLevelItemDefault} from "../../../../img/svg/merchandise-levels/fourth-level/item-default";
import {FourthLevelItemSelected} from "../../../../img/svg/merchandise-levels/fourth-level/item-selected";
import {
    FourthLevelItemWithWrongArrowColor
} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-arrow-color";
import {FourthLevelItemWithWrongColor} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-color";
import {
    FourthLevelItemWithWrongArrowRotate
} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-arrow-rotate";
import {FourthLevelItemWithWrongText} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-text";
import {FourthLevelItemWithoutDots} from "../../../../img/svg/merchandise-levels/fourth-level/item-without-dots";
import {
    FourthLevelItemWithWrongBorder
} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-border";
import {
    FourthLevelItemWithWrongArrowColorSelected
} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-arrow-color-selected";
import {
    FourthLevelItemWithWrongColorSelected
} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-color-selected";
import {
    FourthLevelItemWithWrongArrowRotateSelected
} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-arrow-rotate-selected";
import {
    FourthLevelItemWithWrongTextSelected
} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-text-selected";
import {
    FourthLevelItemWithoutDotsSelected
} from "../../../../img/svg/merchandise-levels/fourth-level/item-without-dots-selected";
import {
    FourthLevelItemWithWrongBorderSelected
} from "../../../../img/svg/merchandise-levels/fourth-level/item-with-wrong-border-selected";

export const FourthLevelPage: FC = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1)

    return (
        <BaseLevelPage
            className={styles['Level_Page']}
            nextModalTitle="Переходим к последнему этапу?"
            nextModalButtonsText={['Да, самое время', 'Нет, останусь тут']}
            level={4}
            onLeftArrowClick={goBack}
        >
            <div className={styles['Level_Lines']}>
                <div className={styles['Mask_Line_First']}>
                    <BaseItem
                        index={1}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={2}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={3}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={4}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Even']}>
                    <BaseItem
                        index={5}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={6}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={7}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={8}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Odd']}>
                    <BaseItem
                        index={9}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={10}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={11}
                        selectedItem={<FourthLevelItemWithWrongArrowColorSelected />}
                    >
                        <FourthLevelItemWithWrongArrowColor />
                    </BaseItem>
                    <BaseItem
                        index={12}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Even']}>
                    <BaseItem
                        index={13}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={14}
                        selectedItem={<FourthLevelItemWithWrongColorSelected />}
                    >
                        <FourthLevelItemWithWrongColor />
                    </BaseItem>
                    <BaseItem
                        index={15}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={16}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Odd']}>
                    <BaseItem
                        index={17}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={18}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={19}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={20}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Even']}>
                    <BaseItem
                        index={21}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={22}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={23}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={24}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Odd']}>
                    <BaseItem
                        index={25}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={26}
                        selectedItem={<FourthLevelItemWithWrongArrowRotateSelected />}
                    >
                        <FourthLevelItemWithWrongArrowRotate />
                    </BaseItem>
                    <BaseItem
                        index={27}
                        selectedItem={<FourthLevelItemWithWrongTextSelected />}
                    >
                        <FourthLevelItemWithWrongText />
                    </BaseItem>
                    <BaseItem
                        index={28}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Even']}>
                    <BaseItem
                        index={29}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={30}
                        selectedItem={<FourthLevelItemWithoutDotsSelected />}
                    >
                        <FourthLevelItemWithoutDots />
                    </BaseItem>
                    <BaseItem
                        index={31}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={32}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Odd']}>
                    <BaseItem
                        index={33}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={34}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={35}
                        selectedItem={<FourthLevelItemWithWrongBorderSelected />}
                    >
                        <FourthLevelItemWithWrongBorder />
                    </BaseItem>
                    <BaseItem
                        index={36}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Even']}>
                    <BaseItem
                        index={37}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={38}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={39}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={40}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>
                <div className={styles['Mask_Line_Odd']}>
                    <BaseItem
                        index={41}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={42}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={43}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        index={44}
                        selectedItem={<FourthLevelItemSelected />}
                    >
                        <FourthLevelItemDefault />
                    </BaseItem>
                </div>

                <div className={styles['Mask_Line_Even']}>
                    {new Array(4).fill('').map((_, key) => (
                        <BaseItem
                            index={45 + key}
                            selectedItem={<FourthLevelItemSelected />}
                        >
                            <FourthLevelItemDefault />
                        </BaseItem>
                    ))}
                </div>

                <div className={styles['Mask_Line_Odd']}>
                    {new Array(4).fill('').map((_, key) => (
                        <BaseItem
                            index={50 + key}
                            selectedItem={<FourthLevelItemSelected />}
                        >
                            <FourthLevelItemDefault />
                        </BaseItem>
                    ))}
                </div>

                <div className={styles['Mask_Line_Even']}>
                    {new Array(4).fill('').map((_, key) => (
                        <BaseItem
                            index={55 + key}
                            selectedItem={<FourthLevelItemSelected />}
                        >
                            <FourthLevelItemDefault />
                        </BaseItem>
                    ))}
                </div>
            </div>
        </BaseLevelPage>
    )
}