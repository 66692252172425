import React, {FC, useEffect} from 'react'
import styles from './PreloadPage.module.css'
import {GameBasePage} from "../../../components";
import Lottie from "lottie-react";
import lottie from "../../../img/lottie/logo.json";
import {useNavigate} from "react-router-dom";
import {RoutesPath} from "../../../App";
import {fetchFindItemGameInitMethod} from "../../../store/reducers/findItemGame";
import * as timers from "timers";

export const PreloadPage: FC = () => {
    const navigate = useNavigate()

    useEffect(() => {
        let timer = setTimeout(() => {
            fetchFindItemGameInitMethod()
                .then(() => {
                    navigate(
                        `../${RoutesPath.MERCHANDISE_START_PAGE}`,
                        { replace: true }
                    )
                })
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <GameBasePage>
            <div className={styles['PreloadPage_content']}>
                <Lottie
                    className={styles['PreloaderPage_lottie']}
                    animationData={lottie}
                    loop
                />
            </div>
        </GameBasePage>
    )
}