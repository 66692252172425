import {FC, HTMLAttributes} from 'react'

type CupLevelIllustrationProps = HTMLAttributes<HTMLOrSVGElement>
export const CupLevelIllustration: FC<CupLevelIllustrationProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="234"
        fill="none"
        viewBox="0 0 160 234"
        {...props}
    >
        <path
            fill="#57565B"
            stroke="#333"
            strokeWidth="1.27"
            d="M.635 219.8v-5.204l8.216.291.01.001h.148l.09-.027c11.825-3.542 33.655-5.736 57-5.736 23.344 0 45.173 2.194 56.999 5.736l.089.027H123.338l.015-.001 8.208-.392v5.31c.004.409-.243.935-.982 1.566-.73.623-1.853 1.27-3.377 1.916-3.042 1.29-7.545 2.523-13.318 3.592-11.536 2.136-28.03 3.596-47.786 3.596-19.734 0-36.218-1.46-47.754-3.596-5.773-1.069-10.279-2.302-13.326-3.592-1.527-.647-2.654-1.293-3.388-1.917-.744-.631-.995-1.159-.995-1.57z"
        ></path>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="1.27"
            d="M.953 213.491l.052-.035.045-.044.043-.043.043-.043.032-.033.027-.037c5.253-7.066 9.225-16.623 10.252-19.206H120.8c1.492 5.141 3.922 9.828 6.006 13.264a65.83 65.83 0 002.783 4.223 45.694 45.694 0 001.14 1.521 1.86 1.86 0 00.117.139l.043.043h0l.043.043.045.044.052.035c.182.122.336.24.44.352a.42.42 0 01.092.125c-.002.152-.124.493-.689 1.011-.549.503-1.417 1.08-2.646 1.699-2.455 1.236-6.25 2.587-11.543 3.839-10.58 2.501-27.064 4.584-50.585 4.584-23.5 0-39.972-2.083-50.552-4.59-5.293-1.254-9.091-2.609-11.55-3.851-1.233-.622-2.104-1.203-2.657-1.71-.543-.498-.687-.838-.703-1.009a.539.539 0 01.06-.088c.074-.09.175-.177.257-.233zm-.324.337v-.001.001z"
        ></path>
        <g filter="url(#filter0_f_476_115867)">
            <path
                fill="#999"
                d="M50.666 201.739c-.214.129-.214.473.043.559.772.302.986 1.248.472 1.893-.772.86-1.415 1.849-1.672 2.967-.514 2.365.772 4.902-.043 7.182-.386 1.032-1.157 1.849-1.629 2.838-.6 1.204-.557 2.967 1.072 3.311.343.086.686.258.9.602.643 1.118-.643 1.634-1.586 1.677-8.444.602-16.932-.215-25.119-2.365-.729-.172-.557-1.29.215-1.204.685.086 1.671.043 1.8-.645.171-.731-.815-1.29-.857-2.021-.043-.602.514-1.118 1.114-1.333.6-.215 1.243-.215 1.8-.473 1.2-.559 1.672-2.022 2.015-3.312l1.8-6.966c.3-1.204.558-2.839 1.458-3.785 1.157-1.118 3-.731 4.458-.645 2.443.129 4.886.258 7.287.43 1.329.086 2.657.172 3.986.215.515.043 1.458.215 2.315.301.386.086.514.602.172.774z"
            ></path>
        </g>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="1.27"
            d="M26.908 196.937h-.003c-7.722-.88-11.634-1.841-13.6-2.586-.985-.373-1.44-.678-1.64-.858l-.011-.011.011-.01c.2-.18.655-.485 1.64-.858 1.966-.746 5.878-1.706 13.6-2.586h0c10.472-1.201 24.378-1.888 39.193-1.888 14.817 0 28.721.644 39.19 1.888h.003c7.722.88 11.634 1.84 13.601 2.586.984.373 1.439.678 1.639.858l.011.01-.011.011c-.2.18-.655.485-1.639.858-1.967.745-5.879 1.706-13.601 2.586h-.001c-10.428 1.201-24.377 1.888-39.192 1.888-14.817 0-28.721-.645-39.19-1.888zm-15.364-3.58a.05.05 0 000 0zm.002.248l-.002.003.002-.003zm109.106.003l-.001-.003.001.003zm-.001-.248l.001-.003-.001.003z"
        ></path>
        <path
            stroke="#999"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.27"
            d="M4.072 219.683s7.373 3.096 14.317 4.343c6.945 1.247 22.89 3.913 48.738 3.913s50.581-3.311 60.954-8.213"
        ></path>
        <path
            fill="#727272"
            stroke="#333"
            strokeWidth="1.27"
            d="M14.91 103.604H117.286v88.572a.856.856 0 01-.081.081c-.184.164-.609.445-1.532.792-1.845.693-5.512 1.589-12.742 2.426h-.001c-9.826 1.157-22.873 1.759-36.833 1.759-13.914 0-27.006-.645-36.835-1.759-7.229-.837-10.896-1.733-12.74-2.426-.924-.347-1.349-.628-1.533-.792a.877.877 0 01-.08-.081v-88.572zm-.026 88.538l.001.002-.001-.002z"
        ></path>
        <g filter="url(#filter1_f_476_115867)">
            <path
                fill="#999"
                d="M58.286 113.043c1.072.086 2.572-.215 2.529-1.29-.043-.946-1.243-1.247-2.186-1.333-5.616-.387-11.274-.903-16.932-1.118-2.186-.086-6.644-.387-8.144 1.462 2.1 1.935 8.316 1.161 11.102 1.333 4.586.344 9.087.645 13.63.946z"
            ></path>
        </g>
        <path
            fill="#5A5A5A"
            stroke="#333"
            strokeWidth="1.27"
            d="M102.932 106.156h-.001c-9.826 1.158-22.873 1.759-36.833 1.759-13.914 0-27.006-.644-36.835-1.759-7.229-.837-10.896-1.733-12.74-2.425-.886-.333-1.313-.605-1.509-.771.196-.167.623-.439 1.509-.771 1.844-.693 5.512-1.589 12.74-2.426 9.83-1.115 22.92-1.759 36.835-1.759s27.006.644 36.835 1.76c7.229.836 10.896 1.732 12.741 2.425.885.332 1.312.604 1.508.771-.196.166-.623.438-1.508.771-1.845.692-5.512 1.588-12.742 2.425zm-88.048-3.333l.001.003a.045.045 0 01-.001-.003zm.001.27a.013.013 0 01-.001.003l.001-.003zm102.428.003l-.002-.003.002.003zm-.002-.27l.002-.003-.002.003z"
        ></path>
        <g filter="url(#filter2_f_476_115867)">
            <path
                fill="#999"
                d="M37.551 167.769c-1.371.352-2.357 1.485-2.871 2.697-.472 1.212-.557 2.501-.6 3.791l-.514 13.835c-.043 1.486-.043 3.127 1.07 4.182 1.072 1.017 2.744 1.173 4.287 1.251 2.914.195 5.785.391 8.7.547 1.328.078 2.828.117 3.77-.703.944-.782 1.03-2.033 1.072-3.205.172-4.495.343-8.989.472-13.484.085-1.837.085-3.752-.986-5.315-1.029-1.446-2.871-2.306-4.757-2.58-1.757-.273-3.6.196-5.314-.078-1.457-.234-2.743-1.368-4.329-.938z"
            ></path>
        </g>
        <path
            fill="#727272"
            stroke="#333"
            strokeWidth="1.27"
            d="M30.675 104.723h0c-6.962-.848-10.495-1.746-12.27-2.438-.89-.346-1.298-.625-1.474-.788a.721.721 0 01-.08-.084l.019-6.507h98.593l-.02 6.511a.714.714 0 01-.078.083c-.171.161-.574.439-1.46.785-1.77.692-5.302 1.59-12.286 2.438h-.001c-9.425 1.159-22.025 1.785-35.45 1.785-13.425 0-26.025-.626-35.493-1.785zm-13.847-3.342a.028.028 0 000 0zm98.635.009l.001-.002-.001.002z"
        ></path>
        <path
            fill="gray"
            stroke="#333"
            strokeWidth="1.27"
            d="M30.663 97.202h0c-6.96-.785-10.495-1.617-12.275-2.26-.787-.283-1.197-.519-1.403-.673.203-.153.61-.387 1.388-.669 1.773-.641 5.308-1.474 12.29-2.259h0c9.467-1.073 22.064-1.652 35.484-1.652 13.42 0 26.016.579 35.483 1.652h.001c6.96.785 10.495 1.618 12.274 2.26.787.284 1.198.52 1.404.674-.204.152-.61.386-1.388.668-1.774.642-5.308 1.474-12.29 2.26h-.001c-9.467 1.072-22.063 1.652-35.483 1.652-13.42 0-26.017-.58-35.484-1.653zm-13.845-3.087v0zm.002.306v.001h0z"
        ></path>
        <path
            fill="#85B9CA"
            fillOpacity="0.3"
            d="M66.031 0C38.677 0 16.508 2.451 16.508 5.505v87.814c0 3.225 22.169 5.805 49.523 5.805 27.355 0 49.524-2.623 49.524-5.805V5.505C115.555 2.45 93.386 0 66.031 0z"
        ></path>
        <path
            stroke="#E6F0F3"
            strokeOpacity="0.5"
            strokeWidth="1.27"
            d="M17.143 5.507v-.002.002zm0-.002c.002-.01.015-.055.09-.138.103-.113.288-.256.585-.418.592-.323 1.507-.654 2.743-.979 2.46-.647 6.05-1.236 10.52-1.732C40.014 1.248 52.37.635 66.032.635c13.662 0 26.018.612 34.95 1.603 4.47.496 8.06 1.085 10.521 1.732 1.236.325 2.151.656 2.743.979.297.162.482.305.585.418.075.083.088.128.09.138v87.814c0 .001.002.056-.099.172-.105.12-.291.27-.587.44-.591.337-1.505.684-2.74 1.025-2.459.68-6.048 1.303-10.517 1.828-8.93 1.05-21.285 1.705-34.946 1.705-13.66 0-26.015-.644-34.946-1.689-4.469-.522-8.057-1.143-10.516-1.825-1.234-.343-2.147-.691-2.739-1.031-.295-.17-.48-.321-.586-.443-.1-.118-.101-.175-.101-.182V5.505z"
        ></path>
        <path
            stroke="#E6F0F3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeOpacity="0.5"
            strokeWidth="1.27"
            d="M19.01 7.01s1.685 4 47.492 4c45.807 0 47.147-4 47.147-4"
        ></path>
        <g filter="url(#filter3_f_476_115867)" opacity="0.19">
            <path
                fill="#fff"
                d="M42.78 12.523s3.495.501 11.97.501v83.683l-11.97-.695V12.523z"
            ></path>
        </g>
        <path
            fill="#727272"
            stroke="#999"
            strokeWidth="2.54"
            d="M69.013 165.421c-28.46 0-45.967-2.938-51.411-4.026v-49.101c16.101 2.293 33.06 3.417 50.468 3.417 14.351 0 24.891-.796 29.465-1.217V164.4a403.514 403.514 0 01-28.522 1.021z"
        ></path>
        <path
            stroke="#999"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.355"
            d="M38.15 164.541v-50.44l29.02 51.832v-50.789l30.877 49.397M38.06 114.101l-20.805 47.658"
        ></path>
        <path
            fill="#999"
            d="M35.75 113.323l2.4 5.419 3-5.247-5.4-.172zM64.684 161.875h2.486v4.558l-2.486-4.558zM67.17 114.7v5.29l3.13-.258-3.13-5.032z"
        ></path>
        <path
            stroke="#666"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.27"
            d="M16.503 168.755s6.044 3.569 41.065 4.644c26.19.817 43.68-1.763 43.68-1.763M16.503 178.346s6.044 3.569 41.065 4.644c26.19.817 43.68-1.763 43.68-1.763"
        ></path>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="1.27"
            d="M112.98 184.709l-.06-.02-.063-.008a1.767 1.767 0 01-1.228-.763 2.162 2.162 0 01-.365-1.486c.272-1.709 1.511-3.606 3.693-5.945 2.1-2.25 4.98-4.816 8.47-7.926l.36-.321h.001c7.708-6.831 17.411-15.437 24.619-25.873 6.888-9.928 8.969-24.063 5.358-36.022-2.131-7.112-6.168-12.435-11.13-14.426l-.004-.001c-2.456-.968-4.764-1.464-6.877-1.464-1.761 0-3.395.363-4.893 1.047l-.004.001c-3.629 1.684-5.597 4.987-6.473 6.744-.144.289-.108.606-.034.827l.003.012.005.011c2.448 6.521 2.402 11.856.042 16.02l-.001.002c-1.715 3.051-4.366 4.878-6.66 5.95-2.193 1.026-4.019 1.343-4.284 1.382a1.868 1.868 0 00-.235.028l-.01.001a1.385 1.385 0 01-.071.011l-.018.002c-.933 0-1.772-.718-1.9-1.645h0l-.001-.008a1.939 1.939 0 011.614-2.202l.013-.002.013-.003h.002l.006-.001.021-.004.067-.013.216-.045a14.496 14.496 0 002.922-.994c1.691-.784 3.708-2.128 4.955-4.375l.001-.001c1.897-3.434 1.558-8.095-.739-13.748l-.006-.013-.006-.013c-.178-.387-.188-.858-.021-1.328l.007-.02.005-.02.008-.023.025-.074a16.106 16.106 0 01.464-1.13c.331-.724.833-1.697 1.538-2.755 1.411-2.121 3.613-4.56 6.839-6.066h.002c2.007-.946 4.228-1.402 6.545-1.402 2.564 0 5.398.58 8.337 1.76l.003.001c6.063 2.4 10.849 8.354 13.428 16.923 3.933 13.067 1.601 28.519-5.915 39.363-7.495 10.809-17.297 19.535-25.233 26.593h-.001c-3.04 2.707-5.745 5.139-7.727 7.127-.99.994-1.814 1.891-2.413 2.665-.578.747-1.018 1.468-1.123 2.1a1.943 1.943 0 01-1.903 1.58c-.068 0-.106 0-.14-.003a.254.254 0 01-.044-.007z"
        ></path>
        <path
            stroke="#727272"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.27"
            d="M114.364 120.117c3.601-.903 6.773-3.526 8.316-6.924 1.543-3.397 1.457-7.525-.257-10.837.085.129.128.301.214.43"
        ></path>
        <path
            stroke="#999"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.27"
            d="M121.866 97.928c3.472-7.87 10.887-11.31 18.732-9.031 7.844 2.279 14.102 10.407 16.074 19.094"
        ></path>
        <path
            fill="#4D4D4D"
            stroke="#333"
            strokeWidth="1.27"
            d="M19.846 189.019c-.933 0-1.722-.789-1.722-1.73s.79-1.73 1.723-1.73 1.722.789 1.722 1.73-.79 1.73-1.723 1.73zM31.042 189.009c0 .957-.773 1.73-1.723 1.73-.949 0-1.722-.773-1.722-1.73 0-.958.773-1.731 1.723-1.731.949 0 1.722.773 1.722 1.731zM40.165 191.814c-.934 0-1.723-.789-1.723-1.73s.79-1.73 1.723-1.73 1.722.789 1.722 1.73a1.72 1.72 0 01-1.722 1.73zM53.118 190.644c0 .957-.773 1.73-1.723 1.73-.95 0-1.722-.773-1.722-1.73 0-.958.773-1.731 1.722-1.731.95 0 1.723.773 1.723 1.731zM64.648 191.288c0 .958-.773 1.73-1.722 1.73-.95 0-1.723-.772-1.723-1.73 0-.957.773-1.73 1.723-1.73.949 0 1.722.773 1.722 1.73zM76.35 190.901c0 .958-.772 1.731-1.722 1.731s-1.722-.773-1.722-1.731c0-.957.773-1.73 1.722-1.73.95 0 1.723.773 1.723 1.73zM87.796 190.6c0 .957-.773 1.73-1.723 1.73-.949 0-1.722-.773-1.722-1.73 0-.958.773-1.731 1.722-1.731.95 0 1.723.773 1.723 1.731zM96.832 191.299c-.933 0-1.722-.789-1.722-1.731 0-.941.79-1.73 1.722-1.73.934 0 1.723.789 1.723 1.73a1.72 1.72 0 01-1.723 1.731z"
        ></path>
        <defs>
            <filter
                id="filter0_f_476_115867"
                width="49.139"
                height="43.394"
                x="12.481"
                y="189.726"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_476_115867"
                    stdDeviation="5.079"
                ></feGaussianBlur>
            </filter>
            <filter
                id="filter1_f_476_115867"
                width="37.422"
                height="13.986"
                x="28.473"
                y="104.151"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_476_115867"
                    stdDeviation="2.54"
                ></feGaussianBlur>
            </filter>
            <filter
                id="filter2_f_476_115867"
                width="34.656"
                height="41.676"
                x="25.934"
                y="160.054"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_476_115867"
                    stdDeviation="3.81"
                ></feGaussianBlur>
            </filter>
            <filter
                id="filter3_f_476_115867"
                width="29.748"
                height="101.961"
                x="33.891"
                y="3.635"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_476_115867"
                    stdDeviation="4.444"
                ></feGaussianBlur>
            </filter>
        </defs>
    </svg>
)