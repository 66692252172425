import React, {DragEvent, FC, MouseEvent, UIEvent, useEffect, useRef, useState} from 'react'
import {pageType} from '../../store/types/book'
import classes from '../../pages/ExpandedBookPage/ExpandedBookPage.module.css'
import {textFormat} from '../../utils/text'
import {Illustration} from './Illustration'
import {getMobileOperatingSystem} from "../../utils/platform";

interface props extends Partial<pageType> {
    index: number
    isScrollTrackHide?: boolean
    onNoteScrollHandler: () => void
    onScrollTrackHide: (isScrollExist: boolean) => void
    currentNoteIndex: number
}


export const Page: FC<props> = (props) => {
    const platform = getMobileOperatingSystem()
    const ref = useRef(null)
    const [isScrollTrackHide, setIsScrollTrackHide] = useState(false)
    const [thumbPosition, setThumbPosition] = useState(0)
    const { index, title, image, text, currentNoteIndex, onNoteScrollHandler, onScrollTrackHide } = props
    const isPageScrolledThrough = thumbPosition >= 10
    const isPageBottomEnd = thumbPosition >= 430
    const isiOS = platform === 'iOS'
    const isFirstPreviewPage = !currentNoteIndex
    const scrollViewCondition = !isScrollTrackHide && !isFirstPreviewPage && !isiOS

    useEffect(() => {
        // @ts-ignore
        const isNoScroll = ref?.current?.scrollHeight === ref?.current?.offsetHeight
        !image && scrollHandler(isNoScroll)
    }, [currentNoteIndex])

    if (image) return <Illustration key={index} src={image} index={index} />

    const scrollHandler = (isScrollExist: boolean) => {
        onScrollTrackHide(isScrollExist)
        setIsScrollTrackHide(isScrollExist)
    }

    function onDivScroll(event: UIEvent<HTMLDivElement>) {
        const { clientHeight, scrollHeight, scrollTop } = event.currentTarget
        const percentOfScrollPage = scrollTop / (scrollHeight - clientHeight) * 100
        const thumbTop = (percentOfScrollPage * 440 / 100)
        setThumbPosition(thumbTop)
        onNoteScrollHandler()
    }


    function onDivMouseMove(event: MouseEvent<HTMLDivElement>) {
        // event.stopPropagation()
        // event.preventDefault()
        // const newPosition = event.pageY - 40
        // const thumbY = newPosition > 0 ? newPosition >= 440 ? 440 : newPosition : 0
        // console.log(thumbY, 'thumbY[log]')
        // if (newPosition >= 440) return
        // // setThumbPosition(thumbY)
        // // @ts-ignore
        // const { clientHeight, scrollHeight } = ref?.current
        // const percentOfScrollPage = newPosition * 100 / 440
        // const scrollTop = (((scrollHeight * percentOfScrollPage) / 100)) / 2
        // // console.log(scrollTop, scrollHeight, clientHeight)
        // // @ts-ignore
        // ref?.current?.scrollTo(0, scrollTop)
        // console.log(scrollTop, 'scrollTop[log]')
    }

    return (
        <>
            <div
                ref={ref}
                key={index}
                className={
                    image
                        ? classes.Book_Illustration
                        : classes.Book_Paragraph
                }
                style={{margin: !index ? '30px 85px' : '30px 88px 50px 0'}}
                onScroll={onDivScroll}>
                {
                    !isScrollTrackHide && isPageScrolledThrough && (
                        <div
                            className={classes.Linear_Gradient}
                            style={{top: 25, right: !index ? '' : 110, left: !index ? 80 : ''}}
                        />
                    )
                }
                {
                    !!title && <h1>{title}</h1>
                }
                {
                    !!text && textFormat(text).map(i => i)
                }
                {
                    !isScrollTrackHide && !isPageBottomEnd && (
                        <div
                            className={classes.Linear_Gradient}
                            style={{ bottom: 90, transform: 'rotate(-180deg)', right: !index ? '' : 110, left: !index ? 80   : ''}}
                        />
                    )
                }
                {
                    scrollViewCondition && (
                        <div
                            className={classes.Custom_Scrollbar}
                            style={{right: !index ? '52%' : 80}}
                        >
                            <div
                                className={classes.Custom_Thumb}
                                style={{marginTop: thumbPosition}}
                            />
                        </div>
                    )
                }
            </div>
        </>
    )
}