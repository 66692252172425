import React, {FC, useRef, useState} from 'react'
import classes from './ExpandedBookPage.module.css'
import book from '../../img/book/expanded-book.svg'
import {ShareButtons} from '../../components/ShareButton/Buttons'
import {useAtomValue} from '@mntm/precoil'
import {atomNotes, atomReadingInfo} from '../../store'
import {CSSTransition, SwitchTransition} from 'react-transition-group'
import {api} from '../../api/notes'
import {ScrollHint} from '../../components/ScrollHint'
import {Arrows, Page} from "../../components/Book"

export const ExpandedBookPage: FC = () => {
    const ref = useRef(null)
    const [isHorizontalHintView, setIsHorizontalHintView] = useState(true)
    const [isVerticalHintView, setIsVerticalHintView] = useState(true)
    const { lastSeenStoryIndex } = useAtomValue(atomReadingInfo)
    const { data: notes } = useAtomValue(atomNotes)
    const isMobile = window.innerWidth <= 900
    const [isScrollTrackHide, setIsScrollTrackHide] = useState(false)


    const [currentNoteIndex, setCurrentNoteIndex] = useState(lastSeenStoryIndex)
    const currentNote = notes[currentNoteIndex]
    const noteWithoutScroll = currentNote.pages
        .filter(note => !!note.image) === currentNote.pages
    const horizontalHintCondition = !noteWithoutScroll && isVerticalHintView && !isScrollTrackHide

    const goToNextBookPage = () => {
        // @ts-ignore
        ref?.current?.scrollTo(0, 0)

        api.readNote(currentNote?.storyID)
        const noteIndex = currentNoteIndex + 1 > notes.length ? currentNoteIndex : currentNoteIndex + 1
        setCurrentNoteIndex(noteIndex)
    }

    const goToPrevBookPage = () => setCurrentNoteIndex(prev => !prev ? 0 : prev - 1)

    const onBookScrollHandler = () => isHorizontalHintView && setIsHorizontalHintView(false)
    const onNoteScrollHandler = () => isVerticalHintView && setIsVerticalHintView(false)

    return (
        <div
            ref={ref}
            id="new-year"
            className={classes.content}
            onScroll={onBookScrollHandler}
        >

            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={currentNoteIndex}
                    classNames={isMobile ? 'fade-mobile' : 'fade'}
                    timeout={300}
                >
                    <div
                        style={{ background: `url(${book})` }}
                        className={classes.Expanded_Book}
                    >
                        {
                            currentNote.pages.map((page, key) => (
                                <Page
                                    currentNoteIndex={currentNoteIndex}
                                    key={key}
                                    index={key}
                                    {...page}
                                    onNoteScrollHandler={onNoteScrollHandler}
                                    onScrollTrackHide={setIsScrollTrackHide}
                                />
                            ))
                        }

                        <Arrows
                            noteIndex={currentNoteIndex}
                            toPrevPage={goToPrevBookPage}
                            toNextPage={goToNextBookPage}
                        />
                    </div>
                </CSSTransition>
            </SwitchTransition>

            <div
                className={`${classes.Buttons} ${!isMobile ? `${classes.Desktop}` : ''}`}
            >
                {
                    isHorizontalHintView
                        ? <ScrollHint align="horizontal" />
                        : horizontalHintCondition && <ScrollHint align="vertical" />
                }
                <ShareButtons />
            </div>
        </div>
    )
}
