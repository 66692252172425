import { FC } from "react";
import ReactCardFlip from "react-card-flip";
import styles from "./Card.module.css";

interface ICardProps {
  img: string;
  title: string;
  description: string;
  howToGet: string;
  isFlip: boolean;
  onClick: () => void
}

export const Card: FC<ICardProps> = ({ img, title, description, isFlip, onClick, howToGet }) => {
  return (
    <ReactCardFlip isFlipped={isFlip}>
      <div className={styles["Card__front"]} onClick={onClick}>
        <div className={styles["Img__wrapper"]}>
          <img src={img} alt="" className={styles["Card__img"]} />
        </div>
        <div className={styles["Title__wrapper"]}>
          <h3 className={styles["Card__title"]}>{title}</h3>
        </div>
      </div>
      <div className={styles["Card__back"]} onClick={onClick}>
        <p className={styles["Card__description"]}>{description} Как добраться: {howToGet}</p>
      </div>
    </ReactCardFlip>
  );
};
