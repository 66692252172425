import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import bridge from "@vkontakte/vk-bridge";
import { YMInitializer } from 'react-yandex-metrika';
import './fonts/fonts.css';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const params = new URLSearchParams(window.location.href);
const userId = params.get("vk_user_id") ?? "anonymous";

document.ontouchmove = function(event){
    event.preventDefault();
}

root.render(
  <React.StrictMode>
    <YMInitializer accounts={[88655619]} options={{
      userParams: {
        UserID: userId
      }
    }} />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

bridge.send("VKWebAppInit", {});