import { FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {PreviewBookPage, ChoicePage, HomePage, MenuSelectionPage, ResultPage, ExpandedBookPage} from "./pages";
import {LevelResultPage, PreloadPage, StartPage, SelectLevel} from "./pages/MerchandiseGame"
import { GameResultPage } from "./pages/MerchandiseGame"
import {ModalRoot} from "./modals";
import {SecondLevelPage} from "./pages/MerchandiseGame/GameLevels/SecondLevelPage/SecondLevelPage";
import {FifthLevelPage, FirstGameLevelPage, ThirdLevelPage} from "./pages/MerchandiseGame/GameLevels";
import {FourthLevelPage} from "./pages/MerchandiseGame/GameLevels/FourthLevelPage/FourthLevelPage";

export enum RoutesPath {
    MAIN_PAGE = '/',
    BOOK_PREVIEW_PAGE = '/book-preview',
    BOOK_EXPANDED_PAGE = '/book-expanded',
    JOURNEY_PAGE = '/journey',
    CHOICE_PAGE = '/choice',
    RESULT_PAGE = '/result',
    MERCHANDISE_PRELOAD_PAGE = '/merchandise-preload',
    MERCHANDISE_START_PAGE = '/merchandise-start',
    MERCHANDISE_LEVELS_PAGE = '/merchandise-levels',
    MERCHANDISE_LEVEL_RESULT_PAGE = '/merchandise-result',
    MERCHANDISE_GAME_RESULT_PAGE = '/merchandise-game-result',
    MERCHANDISE_GAME_FIRST_LEVEL_PAGE = '/merchandise-1-level',
    MERCHANDISE_GAME_SECOND_LEVEL_PAGE = '/merchandise-2-level',
    MERCHANDISE_GAME_THIRD_LEVEL_PAGE = '/merchandise-3-level',
    MERCHANDISE_GAME_FOURTH_LEVEL_PAGE = '/merchandise-4-level',
    MERCHANDISE_GAME_FIFTH_LEVEL_PAGE = '/merchandise-5-level',
}
export const isMobile = window.innerWidth < 1000

export const App: FC = () => {
    const location = useLocation();

    return (
        <TransitionGroup>
            <ModalRoot />
            <CSSTransition
                key={location.key}
                timeout={500}
                classNames="fade"
            >
                <Routes>
                    <Route path="/" element={<MenuSelectionPage />} />
                    <Route path="/book-preview" element={<PreviewBookPage />} />
                    <Route path="/book-expanded" element={<ExpandedBookPage />} />
                    <Route path="/journey" element={<HomePage />} />
                    <Route path="/choice" element={<ChoicePage />} />
                    <Route path="/result" element={<ResultPage />} />
                    <Route
                        path={RoutesPath.MERCHANDISE_PRELOAD_PAGE}
                        element={<PreloadPage />}
                    />
                    <Route
                        path={RoutesPath.MERCHANDISE_START_PAGE}
                        element={<StartPage />}
                    />
                    <Route
                        path={RoutesPath.MERCHANDISE_LEVELS_PAGE}
                        element={<SelectLevel />}
                    />
                    <Route
                        path={RoutesPath.MERCHANDISE_LEVEL_RESULT_PAGE}
                        element={<LevelResultPage />}
                    />
                    <Route
                        path={RoutesPath.MERCHANDISE_GAME_RESULT_PAGE}
                        element={<GameResultPage />}
                    />
                    <Route
                        path={RoutesPath.MERCHANDISE_GAME_FIRST_LEVEL_PAGE}
                        element={<FirstGameLevelPage />}
                    />
                    <Route
                        path={RoutesPath.MERCHANDISE_GAME_SECOND_LEVEL_PAGE}
                        element={<SecondLevelPage />}
                    />
                    <Route
                        path={RoutesPath.MERCHANDISE_GAME_THIRD_LEVEL_PAGE}
                        element={<ThirdLevelPage />}
                    />
                    <Route
                        path={RoutesPath.MERCHANDISE_GAME_FOURTH_LEVEL_PAGE}
                        element={<FourthLevelPage />}
                    />
                    <Route
                        path={RoutesPath.MERCHANDISE_GAME_FIFTH_LEVEL_PAGE}
                        element={<FifthLevelPage />}
                    />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    )
}