import {setAtomNotes, setReadingInfo} from '../atoms/book'
import {backendStoriesType} from '../types/book'
import {api} from '../../api/notes'
import {errorCatch} from '../../utils/stateErrorCatch'
import {getUrlParams} from '../../utils/url'

export async function fetchNotes() {
    try {
        const [_, userFromID] = window.location.hash
            .split('#from=')

        const response = await api.notes<backendStoriesType>(userFromID)
        if (!response.stories) return errorCatch(setAtomNotes, null)

        const userID = getUrlParams('vk_user_id')
        if (!userID) return

        const { stories, lastSeenStoryID } = response
        const lastPageIndex = stories
            .findIndex(i => i.storyID === lastSeenStoryID)

        setAtomNotes({
            isLoaded: true,
            data: stories
        })
        setReadingInfo({
            userID: +userID,
            lastSeenStoryID,
            lastSeenStoryIndex: lastPageIndex === -1
                ? 0
                : lastPageIndex
        })
    } catch (e) {
        errorCatch(setAtomNotes, null)
    }
}