import {atom} from "@mntm/precoil";

type MerchandiseGameInfoTypes = {
    level: number
    backgroundColor: string
    componentColor: string
    stars: number
    points: number
    isAllLevelsPassed?: boolean
}

export const atomMerchandiseGameInfoDefaultState = {
    level: 1,
    stars: 1,
    isAllLevelsPassed: false,
    points: 10,
    backgroundColor: '#B1D5F5',
    componentColor: '#6CA8DC'
}

export const atomMerchandiseGameInfo = atom<MerchandiseGameInfoTypes>(
    { ... atomMerchandiseGameInfoDefaultState},
    'atomMerchandiseGameInfo'
)

export const atomMerchandiseGameResult = atom({
    points: 0
}, 'atomMerchandiseGameResult')

export const setAtomMerchandiseGameInfo = atomMerchandiseGameInfo.set
export const setAtomMerchandiseGameResult = atomMerchandiseGameResult.set