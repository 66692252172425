import { FC } from 'react'
import styles from './Hint.module.css'
import {Button} from "../../components";
import {isMobile} from "../../App";
import {closeModal} from "../../store/atoms/modal";

export const HintModal: FC = () => {

    return (
        <div className={styles['Hint_Modal']}>
            <ol className={styles['Hint_Text']}>
                <li
                    dangerouslySetInnerHTML={{__html: 'Нажмите на&nbsp;предмет, чтобы выбрать его'}}
                />
                <li
                    dangerouslySetInnerHTML={{__html: 'Чтобы отменить выбор, нажмите на&nbsp;предмет второй раз'}}
                />
                <li
                    dangerouslySetInnerHTML={{__html: 'Чтобы перейти дальше, выберите хотя&nbsp;бы один предмет'}}
                />
                {
                    isMobile &&
                    <li
                        dangerouslySetInnerHTML={{__html: 'Чтобы увидеть все предметы, перемещайтесь по&nbsp;экрану влево и&nbsp;вправо'}}
                    />
                }
            </ol>

            <div className={styles['Hint_Action']}>
                <Button
                    size="l"
                    mode="gray"
                    onClick={closeModal}
                >
                    понятно
                </Button>
            </div>
        </div>
    )
}