import axios from 'axios'

export const xvk = window.location.search.slice(1) || 'vk_access_token_settings=&vk_app_id=8142558&vk_are_notifications_enabled=0&vk_is_app_user=0&vk_is_favorite=0&vk_language=ru&vk_platform=desktop_web&vk_ref=other&vk_testing_group_id=1&vk_ts=1679751804&vk_user_id=274487787&sign=2aoN9_ViMtS0Pmq4g4bNrWxiHCNw_r5q87Ap1lsoBwk'
// export const xvk = window.location.search.slice(1)
export const instance = axios.create({
    baseURL: 'https://b.rzdapp.projects.aaaaa.team',
    headers: {
        Accept: 'application/json',
        xvk
    }
})

export const api = {

    notes<T>(userFromID: string): Promise<T> {
        return instance
            .get('api/stories/get/', {
                params: !!+userFromID && {
                    userFrom: userFromID
                }
            })
            .then(({data}) => data)
    },

    readNote(storyID: number) {
        return instance
            .get(`api/stories/read/?storyID=${storyID}`)
            .then(({data}) => data)
    }
}

