import {FC, HTMLAttributes, MouseEventHandler} from 'react'
import styles from './GameBasePage.module.css'
import {getClassNames} from "../../utils/classNames";
import {GameLevelArrowLeft} from "../../img/svg/gameLevelArrowLeft";
import {GameLevelArrowRight} from "../../img/svg/gameLevelArrowRight";

interface GameBasePageProps extends HTMLAttributes<HTMLDivElement> {
    withArrows?: boolean
    wrapperClassName?: string
    onLeftArrowClick?: MouseEventHandler<HTMLOrSVGElement>
    onRightArrowClick?: MouseEventHandler<HTMLOrSVGElement>
    leftArrowDisabled?: boolean
    rightArrowDisabled?: boolean
}

export const GameBasePage: FC<GameBasePageProps> = (props) => {
    const {
        className, withArrows,
        children, wrapperClassName,
        leftArrowDisabled, rightArrowDisabled,
        onRightArrowClick, onLeftArrowClick,
        style, ...restProps
    } = props
    const isMobile = window.innerWidth < 1000
    const pageClassName = getClassNames(
        styles['GameBasePage_content'],
        className
    )
    const wrapperPageClassName = getClassNames(
        styles['GameBasePage_wrapper'],
        wrapperClassName
    )
    const leftArrowClassName = getClassNames(
        styles['Game_Arrow'],
        styles['Arrow_left'],
        leftArrowDisabled && styles['Arrow--disabled']
    )
    const rightArrowClassName = getClassNames(
        styles['Game_Arrow'],
        styles['Arrow_right'],
        rightArrowDisabled && styles['Arrow--disabled']
    )

    return (
        <div
            className={wrapperPageClassName}
            {...restProps}
        >
            { withArrows && !isMobile &&
                <GameLevelArrowLeft
                    onClick={e => !leftArrowDisabled && onLeftArrowClick && onLeftArrowClick(e)}
                    className={leftArrowClassName}
                />
            }
            <div
                id="Game_Page"
                className={pageClassName}
                style={style}
            >
                {children}
            </div>
            { withArrows && !isMobile &&
                <GameLevelArrowRight
                    onClick={e => !rightArrowDisabled && onRightArrowClick && onRightArrowClick(e)}
                    className={rightArrowClassName}
                />
            }
        </div>
    )
}