import React, {FC} from 'react'
import {ButtonShare} from './ShareButton'
import bridge from '@vkontakte/vk-bridge'
import {useAtomValue} from '@mntm/precoil'
import {atomReadingInfo} from '../../store'
import {ShareIcon} from "../../img/svg/share";
import {Message} from "../../img/svg/message";


export const ShareButtons: FC = () => {
    const { userID } = useAtomValue(atomReadingInfo)
    const windowWidth = window.innerWidth
    const isMobile = windowWidth <= 900

    const createToMessages = () => bridge.send('VKWebAppShare', {
        link: `https://vk.com/app8142558#from=${userID}`
    })

    const createWallPost = () => bridge.send('VKWebAppShowWallPostBox', {
        message: `Присоединяйтесь ко мне и получите шанс выиграть подарок!
https://vk.com/app8142558#from=${userID}`,
        attachments: 'photo-212614968_457239018'
    })

    const shareToStories = () => bridge.send('VKWebAppShowStoryBox', {
        request_id: 1,
        background_type: 'image',
        locked: true,
        url: 'https://i.imgur.com/lI9cxVv.png',
        attachment: {
            text: 'go_to',
            type: 'url',
            url: `https://vk.com/app8142558#from=${userID}`
        }
    })

    return (
        <>
            {
                !isMobile
                    ?
                    <>

                        <ButtonShare
                            icon={<ShareIcon />}
                            onClick={createWallPost}
                        >
                            Поделиться в ленте
                        </ButtonShare>
                        <ButtonShare
                            icon={<ShareIcon />}
                            onClick={shareToStories}
                        >
                            Поделиться в историях ВКонтакте
                        </ButtonShare>
                    </>
                    :
                    <>
                        <ButtonShare
                            icon={<ShareIcon />}
                            onClick={createWallPost}
                        >
                            Поделиться в ленте
                        </ButtonShare>
                        <ButtonShare
                            icon={<ShareIcon />}
                            onClick={shareToStories}
                        >
                            Поделиться в историях ВКонтакте
                        </ButtonShare>
                        <ButtonShare
                            icon={<Message width={23} height={23} fill="#242E6A" />}
                            onClick={createToMessages}
                        >
                            Поделиться в личных сообщениях
                        </ButtonShare>
                    </>
            }
        </>
    )
}