import React, {FC, useEffect, useState} from 'react'
import styles from './FirstLevelPage.module.css'
import {useNavigate} from "react-router-dom";
import {BaseLevelPage} from "../../BaseLevelPage";
import {BaseItem} from "../../../../img/svg/merchandise-levels/base-item";
import {ThirdBaseItem} from "../../../../img/svg/merchandise-levels/third-item";
import {FirstLevelItemDefault} from "../../../../img/svg/merchandise-levels/first-level/item-default";
import {FirstLevelItemDefaultSelected} from "../../../../img/svg/merchandise-levels/first-level/item-default-selected";
import {
    FirstLevelItemBackgroundColor2
} from "../../../../img/svg/merchandise-levels/first-level/item-background-color-2";
import {
    FirstLevelItemBackgroundColor2Selected
} from "../../../../img/svg/merchandise-levels/first-level/item-background-color-2-selected";
import {FirstLevelItemWrongText} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-text";
import {
    FirstLevelItemWrongTextSelected
} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-text-selected";
import {FirstLevelItemWrongLogo} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-logo";
import {
    FirstLevelItemWrongLogoSelected
} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-logo-selected";
import {
    FirstLevelItemWrongDesignation
} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-designation";
import {
    FirstLevelItemWrongDesignationSelected
} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-designation-selected";
import {FirstLevelItemWrongLogoColor} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-logo-color";
import {
    FirstLevelItemWrongLogoColorSelected
} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-logo-color-selected";
import {FirstLevelItemWrongBorder} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-border";
import {
    FirstLevelItemWrongBorderSelected
} from "../../../../img/svg/merchandise-levels/first-level/item-wrong-border-selected";
import {FirstLevelItemBackgroundColor} from "../../../../img/svg/merchandise-levels/first-level/item-background-color";

export const FirstGameLevelPage: FC = () => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    return (
        <BaseLevelPage
            className={styles['Level_Page']}
            nextModalTitle="Все объекты найдены?"
            nextModalButtonsText={['Да, идём дальше!', 'Нет, посмотрю ещё']}
            level={1}
            onLeftArrowClick={goBack}
        >
            <div className={styles['Level_Lines']}>
                <div className={styles['Level_Line_First']}>
                    {
                        new Array(8).fill('')
                            .map((_, key) => (
                                <BaseItem
                                    selectedItem={<FirstLevelItemDefaultSelected />}
                                    index={key + 1}
                                >
                                    <FirstLevelItemDefault />
                                </BaseItem>
                            ))
                    }
                </div>


                <div className={styles['Level_Line_Even']}>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={9}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemBackgroundColor2Selected />}
                        index={10}
                    >
                        <FirstLevelItemBackgroundColor2 />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemWrongTextSelected />}
                        index={11}
                    >
                        <FirstLevelItemWrongText />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={12}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={13}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemWrongLogoSelected />}
                        index={14}
                    >
                        <FirstLevelItemWrongLogo />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={15}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                </div>

                <div className={styles['Level_Line_Odd']}>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={16}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemWrongDesignationSelected />}
                        index={17}
                    >
                        <FirstLevelItemWrongDesignation />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={18}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemWrongLogoColorSelected />}
                        index={19}
                    >
                        <FirstLevelItemWrongLogoColor />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={20}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={21}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={22}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={23}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                </div>

                <div className={styles['Level_Line_Even']}>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={24}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemWrongBorderSelected />}
                        index={25}
                    >
                        <FirstLevelItemWrongBorder />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={26}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={27}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={28}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemBackgroundColor2Selected />}
                        index={29}
                    >
                        <FirstLevelItemBackgroundColor />
                    </BaseItem>
                    <BaseItem
                        selectedItem={<FirstLevelItemDefaultSelected />}
                        index={30}
                    >
                        <FirstLevelItemDefault />
                    </BaseItem>
                </div>

                <div className={styles['Level_Line_Odd']}>
                    {
                        new Array(7).fill('')
                            .map((_, key) => (
                                <BaseItem
                                    selectedItem={<FirstLevelItemDefaultSelected />}
                                    index={31 + key}
                                >
                                    <FirstLevelItemDefault />
                                </BaseItem>
                            ))
                    }
                </div>
                <div className={styles['Level_Line_Even']}>
                    {
                        new Array(6).fill('')
                            .map((_, key) => (
                                <BaseItem
                                    selectedItem={<FirstLevelItemDefaultSelected />}
                                    index={39 + key}
                                >
                                    <FirstLevelItemDefault />
                                </BaseItem>
                            ))
                    }
                </div>
            </div>
        </BaseLevelPage>
    )
}