import {FC, HTMLAttributes, ReactNode} from 'react'
import styles from './Placeholder.module.css'
import {getClassNames} from "../../utils/classNames";

interface PlaceholderProps extends HTMLAttributes<HTMLDivElement> {
    icon: ReactNode
    title: string
    description: string
    action: ReactNode
    actionClassName?: string
    iconClassName?: string
}

export const Placeholder: FC<Partial<PlaceholderProps>> = (props) => {
    const { title, description, icon, action, actionClassName, iconClassName, className } = props
    const elementClassName = getClassNames(
        styles['Placeholder_element'],
        className
    )
    const actionElementClassName = getClassNames(
        styles['Placeholder_action'],
        actionClassName
    )
    const iconElementClassName = getClassNames(
        styles['Placeholder_icon'],
        iconClassName
    )

    return (
        <div className={elementClassName}>
            <div className={iconElementClassName}>
                { !!icon && icon }
            </div>
            <div className={styles['Placeholder_title']}>
                { !!title && <h1 dangerouslySetInnerHTML={{__html: title.toUpperCase()}} /> }
            </div>
            <div className={styles['Placeholder_description']}>
                { !!description && <h2 dangerouslySetInnerHTML={{__html: description}} /> }
            </div>

            <div className={actionElementClassName}>
                { !!action && action }
            </div>
        </div>
    )
}