import {CSSProperties, FC, MouseEventHandler, ReactNode} from "react";
import styles from './Button.module.css';
import {getClassNames} from "../../utils/classNames";

interface IButtonProps {
    children: string | ReactNode;
    style?: CSSProperties;
    stretched?: boolean
    size: 's' | 'm' | 'l';
    mode?: 'default' | 'gray'
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    active?: boolean;
    className?: string;
}

export const Button: FC<IButtonProps> = (props) => {
    const {
        children, style, size,
        onClick, disabled,
        active, className,
        stretched, mode = 'default'
    } = props
    const btnClassName = getClassNames(
        styles['Button'],
        styles[`Button--mode-${mode}`],
        styles[`Button--size-${size}`],
        disabled && styles['Button--disabled'],
        active && styles['Button--active'],
        stretched && styles['Button--stretched'],
        className
    )

    return (
        <button
            style={style}
            className={btnClassName}
            onClick={onClick}
        >
            {children}
        </button>
    )
}