import {instance} from "./notes";

export const findItemsGameAPI = {

    init<T>(): Promise<T> {
        return instance
            .get('api/findItems/init/')
            .then(({data}) => data)
    },

    selectItems(level: number, items: number[]): Promise<{ level: number, points: number, stars: number }> {
        return instance
            .post('/api/findItems/results/', { level, chosen: items })
            .then(({data}) => data)
    },

    resetProgress() {
        return instance
            .get('/api/findItems/clear-account/')
            .then(({data}) => data)
    }
}

