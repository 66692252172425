import { FC, useEffect, useRef, useState } from "react";
import { Button, Card, Heading, MiniButton, PageBase } from "../../components";
import { ArrowLeft } from "../../img/svg/arrowLeft";
import { ArrowRight } from "../../img/svg/arrowRight";
import styles from "./ResultPage.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Reply } from "../../img/svg/reply";
import { Message } from "../../img/svg/message";
import { Replay } from "../../img/svg/replay";
import { useDebounce } from '../../hooks/useDebounce';
import bridge from "@vkontakte/vk-bridge";
import ym from 'react-yandex-metrika';

interface stateType {
  state: Array<{
    name: string;
    img: string;
    description: string;
    how: string;
    tags: Array<Array<string>>;
  }>;
}

export const ResultPage: FC = () => {
  const [flippedCard, setFlippedCard] = useState<number>();
  const [isClicked, setIsClisked] = useState<boolean>(false);
  const [scrollBack, setScrollBack] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();
  const location = useLocation() as stateType;
  const isMobile = window.innerWidth < 800;
  const { debounce } = useDebounce();

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ym('reachGoal', 'go_to_result_page');
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    function scroll() {
      if (scrollContainerRef.current && !isClicked && !isFlipped) {
        if (scrollContainerRef.current.scrollLeft < scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth && !scrollBack) {
          scrollContainerRef.current.scrollLeft += 1
          timeout = setTimeout(scroll, 16);
        } else if (scrollBack) {
          if (!scrollContainerRef.current.scrollLeft) setScrollBack(false);
          scrollContainerRef.current.scrollLeft -= 1;
          timeout = setTimeout(scroll, 16);
        } else {
          setScrollBack(true);
        }
      }
    }
    scroll();
    return () => clearTimeout(timeout);
  }, [isClicked, scrollBack, isFlipped]);

  const handleArrowClick = (direction: "left" | "right") => {
    setIsClisked(true);
    if (direction === "left" && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft - (scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth) / 2,
        behavior: "smooth"
      });
    } else if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + (scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth) / 2,
        behavior: "smooth"
      });
    }
  }

  const handleAnotherClick = () => {
    navigate("/choice");
  }

  const handleWallClick = () => {
    bridge.send("VKWebAppShowWallPostBox", { "message": "Уже знаю, куда поеду отдыхать. Кто со мной? https://vk.com/app8142558", attachments: "photo-206999520_457239017" })
      .then(_ => {
        ym('reachGoal', 'wallpost');
      });
  }

  const handleMessageClick = () => {
    bridge.send("VKWebAppShare", { "link": "https://vk.com/app8142558" })
      .then(_ => {
        ym('reachGoal', 'share');
      });
  }

  const handleClick = () => {
    if (typeof flippedCard !== "number") return;
    ym('reachGoal', 'go_to_rzd');
    const link = document.createElement("a");
    link.href = "https://www.rzd.ru?utm_source=vkontakte&utm_medium=miniapp&utm_campaign=trips2022";
    link.target = "_blank";
    link.click();
  }

  const handleStoryClick = () => {
    bridge.send("VKWebAppShowStoryBox", {
      locked: true,
      url: "https://sun9-21.userapi.com/s/v1/if2/vghLXF7pjg7LDZ_kBfo5Jh-okHroA5_Qod1JbaOo1skri__0WkNax8OgP5d7qI2oNpue1Dl_6cQQF4F4zfflSfKj.jpg?size=1080x1920&quality=95&type=album",
      background_type: "image",
      attachment: {
        type: "url",
        text: "open",
        url: "https://vk.com/app8142558"
      }
    }).then(_ => {
      ym('reachGoal', 'story');
    });
  }

  const handleScroll = (): void => {
    if (isClicked) debounce(() => setIsClisked(false), 1000);
  }

  return (
    <PageBase overflow="auto">
      <div className={styles["Page__content"]}>
        <div className={styles["Content"]}>
          <Heading className={styles["Heading"]}>Выбор за вами</Heading>
          <div className={styles["Slider__content"]}>
            <div className={styles["Slider__wrapper"]} ref={scrollContainerRef} onScroll={handleScroll} onPointerDown={() => setIsClisked(true)}>
              {location.state.map((elem, index) => (
                <Card
                  title={elem.name}
                  description={elem.description}
                  img={elem.img}
                  howToGet={elem.how}
                  isFlip={flippedCard === index - 1}
                  onClick={() => {
                    if (isFlipped && flippedCard === index - 1) setIsFlipped(false);
                    else setIsFlipped(true);
                    setFlippedCard(
                      flippedCard === index - 1 ? undefined : index - 1
                    )
                  }
                  }
                />
              ))}
            </div>
            {isMobile && (
              <ArrowLeft
                style={{
                  position: "absolute",
                  left: 20,
                  top: "calc(50% - 17px)",
                }}
                onClick={() => handleArrowClick("left")}
              />
            )}
            {isMobile && (
              <ArrowRight
                style={{
                  position: "absolute",
                  right: 20,
                  top: "calc(50% - 17px)",
                }}
                onClick={() => handleArrowClick("right")}
              />
            )}
          </div>
        </div>
        <div className={styles["Button__wrapper"]}>
          <Button
            size="m"
            disabled={typeof flippedCard !== "number"}
            className={styles["Button"]}
            onClick={handleClick}
          >
            Купить билет
          </Button>
        </div>
        <p className={styles["Page__description"]}>
          Нажмите на карточку, чтобы перевернуть её. <br />На обратной стороне
          интересный факт об этом месте.
        </p>
        <div className={styles["Share-button__container"]}>
          <MiniButton icon={<Reply />} onClick={handleWallClick}>Поделиться в ленте</MiniButton>
          {isMobile && <MiniButton icon={<Message />} onClick={handleMessageClick}>Поделиться в личных сообщениях</MiniButton>}
          <MiniButton icon={<Reply />} onClick={handleStoryClick}>Поделиться в историях ВКонтакте</MiniButton>
          <MiniButton icon={<Replay />} onClick={handleAnotherClick}>Хочу в другие места</MiniButton>
        </div>
      </div>
    </PageBase>
  );
};
