import {FC, useContext} from 'react'
import {BaseLevelPage} from "../../BaseLevelPage";
import {SecondLevelBackground} from "../../../../img/svg/merchandise-levels/second-level";
import {useNavigate} from "react-router-dom";
export const SecondLevelPage: FC = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1)

    return (
        <BaseLevelPage
            nextModalTitle="Переходим к следующему уровню?"
            nextModalButtonsText={['Да, я готов', 'Нет, пока останусь тут']}
            level={2}
            onLeftArrowClick={goBack}
        >
            <SecondLevelBackground />
        </BaseLevelPage>
    )
}