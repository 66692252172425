import { FC } from 'react'

type StarProps = { fillColor?: string }

export const StarIcon: FC<StarProps> = ({ fillColor }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="49"
        fill="none"
        viewBox="0 0 52 49"
    >
        <path
            fill={fillColor}
            stroke="#000"
            d="M27.427 2.009l4.608 14.184a2.5 2.5 0 002.378 1.727h14.914c1.454 0 2.058 1.86.882 2.714L38.143 29.4a2.5 2.5 0 00-.908 2.796l4.609 14.184c.449 1.382-1.133 2.531-2.309 1.677L27.47 39.29a2.5 2.5 0 00-2.94 0l-12.065 8.767c-1.176.854-2.758-.295-2.309-1.677l4.61-14.184a2.5 2.5 0 00-.91-2.796L1.792 20.634c-1.176-.854-.571-2.713.882-2.713h14.914a2.5 2.5 0 002.378-1.728l4.608-14.184c.45-1.382 2.405-1.382 2.854 0z"
        ></path>
    </svg>
)