import {FC} from 'react'

type props = {
  width: number
  height: number
  fill: string
}

export const Message: FC<Partial<props>> = (props) => {
  const { width = 16, height = 15, fill =  '#1F4F69'} = props

  return <svg width={width} height={height} viewBox="0 0 16 15" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4135 0.416504H2.58827C1.43661 0.416504 0.5 1.34065 0.5 2.47307V9.79794C0.5 10.9321 1.43661 11.8545 2.58827 11.8545H9.46268L11.8389 14.2559C12.0539 14.4729 12.269 14.5832 12.4769 14.5832C12.7648 14.5832 13.0723 14.3679 13.0723 13.7623V11.8545H13.4117C14.5634 11.8545 15.5 10.9321 15.5 9.79794V2.47307C15.5018 1.34065 14.5652 0.416504 13.4135 0.416504Z" />
  </svg>

}