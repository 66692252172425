import { FC } from 'react'

export const ThirdItemWithWater: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="126"
            height="184"
            fill="none"
            viewBox="0 0 126 184"
        >
            <path
                fill="#57565B"
                stroke="#333"
                d="M.5 173.093v-4.098l6.47.229.009.001H7.095l.07-.022c9.313-2.789 26.504-4.516 44.887-4.516 18.384 0 35.575 1.727 44.888 4.516l.07.022h.119l.012-.001 6.464-.308V173.097c.002.322-.192.737-.774 1.233-.575.491-1.459 1-2.659 1.509-2.396 1.016-5.942 1.987-10.488 2.829-9.085 1.682-22.074 2.832-37.632 2.832-15.54 0-28.521-1.15-37.606-2.832-4.546-.842-8.094-1.813-10.495-2.829-1.202-.509-2.09-1.018-2.667-1.509-.586-.498-.784-.914-.784-1.237z"
            ></path>
            <path
                fill="#4D4D4D"
                stroke="#333"
                d="M.75 168.124l.042-.027.035-.036.033-.033.034-.034.026-.026.021-.029c4.136-5.565 7.265-13.091 8.073-15.125H95.13c1.176 4.048 3.09 7.74 4.73 10.445a52.25 52.25 0 002.192 3.326 36.47 36.47 0 00.879 1.174l.057.071c.003.004.03.038.055.062l.033.034h0l.034.034.035.035.041.028c.144.095.265.188.347.276.053.057.069.09.073.099-.002.119-.098.388-.544.796-.432.396-1.115.851-2.083 1.338-1.933.973-4.922 2.037-9.09 3.023-8.332 1.97-21.313 3.61-39.836 3.61-18.505 0-31.478-1.64-39.81-3.614-4.168-.988-7.159-2.055-9.096-3.033-.97-.49-1.656-.947-2.092-1.347-.427-.392-.54-.66-.553-.795a.477.477 0 01.047-.069.997.997 0 01.203-.183zm-.255.265v-.001.001z"
            ></path>
            <g filter="url(#filter0_f_442_67602)">
                <path
                    fill="#999"
                    d="M39.9 158.87c-.168.101-.168.372.034.44.608.237.777.982.371 1.49-.607.677-1.114 1.456-1.316 2.337-.405 1.862.608 3.86-.034 5.655-.304.813-.911 1.457-1.283 2.235-.472.949-.438 2.337.844 2.608.27.068.54.203.71.474.506.881-.507 1.287-1.25 1.321a60.841 60.841 0 01-19.781-1.863c-.574-.135-.439-1.016.169-.948.54.068 1.316.034 1.418-.508.135-.576-.642-1.016-.676-1.591-.033-.475.405-.881.878-1.05.473-.17.979-.17 1.418-.373.945-.44 1.316-1.592 1.586-2.608l1.418-5.486c.236-.948.439-2.235 1.148-2.98.911-.88 2.363-.576 3.51-.508 1.925.102 3.849.203 5.74.339 1.046.067 2.092.135 3.139.169.405.034 1.147.169 1.822.237.304.068.405.474.135.61z"
                ></path>
            </g>
            <path
                fill="#4D4D4D"
                stroke="#333"
                d="M21.19 155.088h-.002c-6.081-.693-9.163-1.449-10.711-2.037-.776-.294-1.134-.533-1.29-.675a.403.403 0 01-.01-.009l.01-.008c.156-.142.514-.381 1.29-.675 1.548-.588 4.63-1.344 10.71-2.037h.001c8.246-.945 19.197-1.487 30.864-1.487 11.668 0 22.618.508 30.862 1.487h.002c6.081.693 9.162 1.449 10.71 2.037.776.294 1.135.533 1.291.675l.01.008a.777.777 0 01-.01.009c-.156.142-.515.381-1.29.675-1.549.588-4.63 1.344-10.711 2.037h0c-8.213.945-19.197 1.487-30.864 1.487-11.669 0-22.618-.508-30.862-1.487zm-12.1-2.819l.002.002a.005.005 0 01-.002-.002zm.002.195l-.002.002.002-.002zm85.921.002l-.001-.002.001.002zm-.001-.195l.001-.002-.001.002z"
            ></path>
            <path
                stroke="#999"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M3.207 173s5.806 2.438 11.275 3.42c5.468.983 18.026 3.082 38.38 3.082 20.356 0 39.833-2.607 48.002-6.468"
            ></path>
            <path
                fill="#727272"
                stroke="#333"
                d="M81.06 153.936h-.002c-7.737.912-18.012 1.386-29.005 1.386-10.958 0-21.268-.508-29.008-1.386-5.693-.659-8.58-1.364-10.033-1.91-.728-.273-1.062-.494-1.207-.623a.758.758 0 01-.064-.064V81.588H92.364v69.751a.743.743 0 01-.064.064c-.145.129-.48.35-1.207.623-1.452.546-4.34 1.251-10.034 1.91zm-69.339-2.624l.001.002v-.002zm80.663 0l-.001.002v-.002z"
            ></path>
            <g filter="url(#filter1_f_442_67602)">
                <path
                    fill="#999"
                    d="M45.9 89.021c.844.068 2.026-.17 1.992-1.016-.034-.745-.98-.982-1.722-1.05-4.422-.304-8.878-.71-13.333-.88-1.722-.068-5.233-.305-6.414 1.151 1.654 1.524 6.549.915 8.743 1.05 3.612.271 7.156.508 10.734.745z"
                ></path>
            </g>
            <path
                fill="#5A5A5A"
                stroke="#333"
                d="M81.06 83.598h-.002c-7.737.911-18.012 1.385-29.005 1.385-10.958 0-21.268-.508-29.008-1.386-5.693-.658-8.58-1.364-10.033-1.91-.698-.261-1.034-.476-1.188-.607.154-.13.49-.345 1.188-.607 1.452-.545 4.34-1.251 10.033-1.91 7.74-.878 18.05-1.385 29.008-1.385 10.957 0 21.267.507 29.007 1.385 5.693.659 8.58 1.365 10.033 1.91.698.262 1.034.476 1.188.607-.154.131-.49.346-1.188.607-1.452.546-4.34 1.252-10.034 1.91zM11.72 80.973l.001.002v-.002zm.001.213v0zm80.662.001l-.001-.001v.001zm-.001-.212v-.002.002z"
            ></path>
            <g filter="url(#filter2_f_442_67602)">
                <path
                    fill="#999"
                    d="M29.572 132.117c-1.08.277-1.857 1.17-2.261 2.124-.372.954-.44 1.97-.473 2.986l-.405 10.895c-.034 1.17-.034 2.462.844 3.293.843.8 2.16.924 3.375.985 2.295.154 4.556.308 6.85.431 1.047.062 2.228.092 2.97-.554.743-.616.81-1.6.845-2.524.135-3.539.27-7.079.37-10.618.068-1.447.068-2.955-.775-4.186-.81-1.139-2.262-1.816-3.747-2.031-1.383-.216-2.835.154-4.185-.062-1.147-.185-2.16-1.077-3.408-.739z"
                ></path>
            </g>
            <path
                fill="#727272"
                stroke="#333"
                d="M24.156 82.47h0c-5.482-.668-8.264-1.375-9.663-1.92-.7-.273-1.021-.493-1.16-.62a.636.636 0 01-.063-.067l.015-5.124h77.642l-.015 5.128a.613.613 0 01-.062.064c-.135.127-.452.347-1.15.62-1.394.544-4.175 1.251-9.675 1.919h0c-7.423.913-17.346 1.405-27.918 1.405s-20.494-.492-27.95-1.405zm-10.904-2.632a.043.043 0 000 0z"
            ></path>
            <path
                fill="gray"
                stroke="#333"
                d="M24.147 76.547h0c-5.481-.618-8.265-1.273-9.666-1.779-.62-.224-.943-.41-1.106-.53.16-.121.48-.305 1.094-.527 1.396-.505 4.18-1.16 9.678-1.78h0c7.455-.844 17.375-1.3 27.944-1.3 10.568 0 20.488.456 27.943 1.3h0c5.481.62 8.265 1.275 9.666 1.78.62.224.943.41 1.106.53-.16.121-.48.305-1.094.527-1.396.506-4.18 1.161-9.678 1.78h0c-7.455.845-17.375 1.3-27.944 1.3-10.568 0-20.488-.455-27.943-1.3zm-10.902-2.19s0 0 0 0h0z"
            ></path>
            <path
                fill="#85B9CA"
                fillOpacity="0.3"
                d="M52 0C30.458 0 13 1.93 13 4.335v69.153c0 2.54 17.458 4.572 39 4.572 21.541 0 39-2.066 39-4.572V4.335C91 1.93 73.54 0 52 0z"
            ></path>
            <path
                stroke="#E6F0F3"
                strokeOpacity="0.5"
                d="M13.5 4.337v-.002.002zm0-.002c.001-.008.012-.043.071-.109a1.79 1.79 0 01.46-.329c.467-.254 1.188-.514 2.16-.77 1.939-.51 4.766-.974 8.286-1.364C31.51.983 41.24.5 52 .5c10.758 0 20.489.482 27.523 1.263 3.52.39 6.347.854 8.285 1.364.973.256 1.693.516 2.16.77.234.128.38.24.46.33.06.065.07.1.072.108v69.153c0 .002.001.045-.079.136a1.805 1.805 0 01-.462.346c-.466.266-1.185.539-2.157.808-1.937.536-4.763 1.026-8.283 1.44-7.032.826-16.762 1.342-27.52 1.342-10.757 0-20.487-.507-27.52-1.33-3.519-.412-6.345-.9-8.28-1.438-.973-.27-1.692-.544-2.158-.811a1.8 1.8 0 01-.461-.35c-.08-.092-.08-.137-.08-.143V4.335zm77 .002v-.002.002z"
            ></path>
            <path
                stroke="#E6F0F3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeOpacity="0.5"
                d="M14.97 5.52s1.328 3.15 37.401 3.15 37.128-3.15 37.128-3.15"
            ></path>
            <g filter="url(#filter3_f_442_67602)" opacity="0.19">
                <path
                    fill="#fff"
                    d="M33.69 9.862s2.752.395 9.426.395v65.9l-9.426-.548V9.862z"
                ></path>
            </g>
            <path
                fill="#85B9CA"
                fillOpacity="0.3"
                fillRule="evenodd"
                d="M13.004 60c.058-.27.875-1.566 11.225-2.719C31.648 56.448 41.506 56 52 56s20.352.448 27.771 1.281C90.666 58.498 91 59.875 91 60.035c0 .05-.028.21-.373.445V72.7c.21.175.232.295.232.335 0 .16-.3 1.538-11.208 2.755-7.406.832-17.247 1.28-27.721 1.28-10.475 0-20.316-.447-27.721-1.28C13.334 74.573 13 73.196 13 73.035c0-.008 0-.02.004-.035H13V60h.004z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#85B9CA"
                fillOpacity="0.3"
                d="M52 64.07c-10.494 0-20.352-.447-27.771-1.28C13.334 61.573 13 60.196 13 60.035c0-.16.3-1.537 11.229-2.754C31.648 56.448 41.506 56 52 56s20.352.448 27.771 1.281C90.666 58.498 91 59.875 91 60.035c0 .16-.3 1.538-11.229 2.755-7.419.833-17.277 1.28-27.771 1.28z"
            ></path>
            <path
                stroke="#E6F0F3"
                strokeOpacity="0.5"
                d="M24.285 62.293h0c-5.437-.607-8.198-1.251-9.588-1.748-.6-.214-.92-.393-1.084-.512.162-.118.478-.295 1.072-.508 1.385-.496 4.146-1.14 9.6-1.747h0C31.679 56.948 41.518 56.5 52 56.5s20.321.448 27.715 1.278h0c5.437.607 8.198 1.25 9.588 1.748.6.214.92.392 1.084.512-.162.118-.478.295-1.072.508-1.385.496-4.146 1.14-9.6 1.747h0c-7.394.83-17.233 1.278-27.715 1.278s-20.321-.448-27.715-1.278z"
            ></path>
            <path
                fill="#727272"
                stroke="#999"
                strokeWidth="2"
                d="M54.348 130.269c-22.413 0-36.199-2.314-40.487-3.171V88.431c12.68 1.806 26.036 2.691 39.744 2.691 11.302 0 19.602-.627 23.203-.958v39.301a318.08 318.08 0 01-22.46.804z"
            ></path>
            <path
                stroke="#999"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.854"
                d="M30.043 129.577V89.855l22.853 40.817V90.676l24.316 38.901M29.973 89.855l-16.384 37.53"
            ></path>
            <path
                fill="#999"
                d="M28.153 89.242l1.89 4.267 2.364-4.132-4.254-.135zM50.938 127.476h1.958v3.59l-1.958-3.59zM52.897 90.326v4.165l2.464-.203-2.464-3.962z"
            ></path>
            <path
                stroke="#666"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M12.996 132.895s4.76 2.81 32.339 3.657c20.625.643 34.397-1.388 34.397-1.388M12.996 140.447s4.76 2.811 32.339 3.657c20.625.644 34.397-1.388 34.397-1.388"
            ></path>
            <path
                fill="#4D4D4D"
                stroke="#333"
                d="M88.972 145.459l-.047-.016-.05-.006a1.392 1.392 0 01-.967-.601 1.703 1.703 0 01-.288-1.171c.214-1.345 1.19-2.839 2.909-4.681 1.654-1.772 3.921-3.793 6.67-6.242l.283-.252c6.071-5.38 13.712-12.157 19.389-20.376 5.424-7.818 7.063-18.949 4.219-28.367-1.678-5.6-4.857-9.792-8.765-11.36l-.003-.002c-1.934-.762-3.752-1.152-5.416-1.152a9.193 9.193 0 00-3.853.824h0l-.003.001c-2.858 1.327-4.408 3.927-5.098 5.31a.866.866 0 00-.027.653l.003.009.004.008c1.927 5.136 1.892 9.337.033 12.617l-.001.001c-1.35 2.403-3.438 3.841-5.245 4.686-1.726.807-3.165 1.057-3.373 1.088a1.493 1.493 0 00-.185.022h-.008a1.31 1.31 0 01-.07.011c-.735 0-1.396-.566-1.496-1.295h0l-.001-.007a1.527 1.527 0 011.27-1.734l.011-.002.01-.002h.002l.005-.001.016-.003.052-.01a8.648 8.648 0 00.732-.177 11.628 11.628 0 001.74-.641c1.332-.617 2.92-1.676 3.902-3.445v-.001c1.495-2.705 1.228-6.375-.581-10.827l-.005-.01-.004-.01c-.14-.305-.149-.676-.017-1.046l.005-.016.005-.015c0 .002 0-.004.006-.019a3.79 3.79 0 01.093-.26c.065-.168.162-.404.292-.688.26-.57.656-1.336 1.21-2.17 1.112-1.67 2.846-3.591 5.387-4.776l.001-.001c1.581-.744 3.33-1.104 5.155-1.104 2.018 0 4.251.458 6.565 1.386l.002.001c4.775 1.89 8.543 6.58 10.575 13.327 3.097 10.29 1.26 22.459-4.658 30.999-5.902 8.511-13.622 15.383-19.872 20.942h0c-2.394 2.131-4.524 4.046-6.085 5.612-.78.783-1.429 1.489-1.9 2.099-.455.588-.802 1.156-.884 1.654a1.53 1.53 0 01-1.61 1.241c-.022-.002-.032-.005-.034-.005z"
            ></path>
            <path
                stroke="#727272"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M90.061 94.593c2.836-.712 5.334-2.778 6.55-5.453 1.215-2.675 1.147-5.926-.203-8.534.067.102.101.237.168.339"
            ></path>
            <path
                stroke="#999"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M95.969 77.118c2.734-6.197 8.574-8.907 14.751-7.112 6.178 1.795 11.106 8.196 12.659 15.037"
            ></path>
            <path
                fill="#4D4D4D"
                stroke="#333"
                d="M15.63 148.853a1.376 1.376 0 01-1.357-1.362c0-.742.621-1.363 1.356-1.363.735 0 1.357.621 1.357 1.363 0 .741-.622 1.362-1.357 1.362zM24.446 148.845a1.36 1.36 0 01-1.357 1.363 1.36 1.36 0 01-1.357-1.363 1.36 1.36 0 011.357-1.363 1.36 1.36 0 011.357 1.363zM31.63 151.054a1.376 1.376 0 01-1.357-1.362c0-.742.622-1.363 1.357-1.363s1.357.621 1.357 1.363c0 .747-.594 1.362-1.357 1.362zM41.83 150.132a1.36 1.36 0 01-1.356 1.363 1.36 1.36 0 01-1.357-1.363 1.36 1.36 0 011.357-1.362 1.36 1.36 0 011.356 1.362zM50.91 150.64a1.36 1.36 0 01-1.356 1.363 1.36 1.36 0 01-1.357-1.363 1.36 1.36 0 011.357-1.363 1.36 1.36 0 011.356 1.363zM60.126 150.335a1.36 1.36 0 01-1.356 1.363 1.36 1.36 0 01-1.357-1.363 1.36 1.36 0 011.357-1.362 1.36 1.36 0 011.356 1.362zM69.139 150.098a1.36 1.36 0 01-1.357 1.363 1.36 1.36 0 01-1.356-1.363 1.36 1.36 0 011.356-1.363 1.36 1.36 0 011.357 1.363zM76.255 150.648a1.376 1.376 0 01-1.357-1.363c0-.741.622-1.362 1.357-1.362s1.357.621 1.357 1.362c0 .748-.594 1.363-1.357 1.363z"
            ></path>
            <defs>
                <filter
                    id="filter0_f_442_67602"
                    width="38.697"
                    height="34.172"
                    x="9.829"
                    y="149.409"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feGaussianBlur
                        result="effect1_foregroundBlur_442_67602"
                        stdDeviation="4"
                    ></feGaussianBlur>
                </filter>
                <filter
                    id="filter1_f_442_67602"
                    width="29.47"
                    height="11.014"
                    x="22.423"
                    y="82.019"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feGaussianBlur
                        result="effect1_foregroundBlur_442_67602"
                        stdDeviation="2"
                    ></feGaussianBlur>
                </filter>
                <filter
                    id="filter2_f_442_67602"
                    width="27.291"
                    height="32.82"
                    x="20.423"
                    y="126.042"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feGaussianBlur
                        result="effect1_foregroundBlur_442_67602"
                        stdDeviation="3"
                    ></feGaussianBlur>
                </filter>
                <filter
                    id="filter3_f_442_67602"
                    width="23.427"
                    height="80.295"
                    x="26.689"
                    y="2.862"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    ></feBlend>
                    <feGaussianBlur
                        result="effect1_foregroundBlur_442_67602"
                        stdDeviation="3.5"
                    ></feGaussianBlur>
                </filter>
            </defs>
        </svg>
    )
}