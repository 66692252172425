import { FC } from 'react'
import styles from './Share.module.css'
import {Button} from "../../components";
import {createMerchandiseGameStories} from "../../utils/stories";
import bridge from "@vkontakte/vk-bridge";
import {useAtomValue} from "@mntm/precoil";
import {atomMerchandiseGameResult} from "../../store/atoms/merchandiseGame";
import {declination} from "../../utils/text";

export const ShareModal: FC = () => {
    const { points } = useAtomValue(atomMerchandiseGameResult)

    const shareToWall = () => bridge.send('VKWebAppShowWallPostBox', {
        message: `Я набрал ${points} ${
            declination(points, ['балл', 'балла', 'баллов'])
        } в игре «Внимательный пассажир». Проверь свою внимательность и ты!\nhttps://vk.com/app8142558`,
        attachments: 'photo-212614968_457239021'
    })
    const shareToStories = () => createMerchandiseGameStories()
    return (
        <div className={styles['Share_Modal']}>
            <div className={styles['Modal_Text']}>
                <h1>Поделиться</h1>
            </div>

            <div className={styles['Modal_Actions']}>
                <Button
                    size="l"
                    mode="gray"
                    onClick={shareToWall}
                >
                    В ленте
                </Button>
                <Button
                    size="l"
                    mode="gray"
                    onClick={shareToStories}
                >
                    В историях ВК
                </Button>
            </div>
        </div>
    )
}