import React, {FC, ReactNode, useEffect, useState} from 'react'
import {GameBasePage} from "../../components";
import styles from "./GameLevels/levels.module.css";
import {isMobile, RoutesPath} from "../../App";
import {MobileGameArrows} from "../../img/svg/mobileGameArrows";
import {LevelContext} from "./GameLevels/context";
import {GameFinishModal, HintModal} from "../../modals";
import {setModal} from "../../store/atoms/modal";
import {findItemsGameAPI} from "../../api/find-items";
import {fetchFindItemGameInitMethod} from "../../store/reducers/findItemGame";
import {levelsCopy} from "./SelectLevel";
import {atomMerchandiseGameInfo, setAtomMerchandiseGameInfo} from "../../store/atoms/merchandiseGame";
import {useNavigate} from "react-router-dom";
import {getClassNames} from "../../utils/classNames";
import {storage} from "../../utils/storage";

interface BaseLevelPageProps {
    withoutArrows?: boolean
    className?: string
    onLeftArrowClick?: React.MouseEventHandler<HTMLOrSVGElement>
    onRightArrowClick?: React.MouseEventHandler<HTMLOrSVGElement>
    children: ReactNode
    nextModalTitle: string
    nextModalButtonsText: string[]
    level: number
    isFinishLevel?: boolean
}

export const BaseLevelPage: FC<BaseLevelPageProps> = (props) => {
    const navigate = useNavigate()
    const { onLeftArrowClick, level, withoutArrows, isFinishLevel, children, nextModalTitle, nextModalButtonsText, className } = props
    const pageClassName = getClassNames(
        styles['Level_Page'],
        className
    )
    const [selectedItems, setSelectedItems] = useState<number[]>([])

    useEffect(() => {
        showHint()
    }, [])

    const showHint = async() => {
        const isShowed = await storage.get('level-hint')
        if (level > 1 || isShowed) return

        storage.set('level-hint', 'show')
        setModal(<HintModal />)
    }

    useEffect(() => {
        document.getElementById('Game_Page')?.scrollTo(window.innerWidth / 2, 0)
    }, [])

    const openNextModal = () => setModal(
        <GameFinishModal
            title={nextModalTitle}
            buttonText={nextModalButtonsText}
            onNextButtonClick={goNext}
        />
    )

    const goBack = async() => {
        console.log(level)
        setAtomMerchandiseGameInfo({
            ...atomMerchandiseGameInfo.get(),
            level
        })
        // await fetchFindItemGameInitMethod()
        navigate(-1)
    }


    const goNext = async() => {
        setModal(null)
        const response = await findItemsGameAPI.selectItems(level, selectedItems)
        console.log(response, 'game result')
        await fetchFindItemGameInitMethod()
        const { buttonColor, backgroundColor } = levelsCopy[level - 1]
        setAtomMerchandiseGameInfo({
            level,
            componentColor: buttonColor,
            backgroundColor,
            stars: response?.stars || 0, points: response.points
        })
        navigate(`../${
            isFinishLevel
                ? RoutesPath.MERCHANDISE_GAME_RESULT_PAGE
                : RoutesPath.MERCHANDISE_LEVEL_RESULT_PAGE
        }`, { replace: true })
    }

    return (
        <LevelContext.Provider value={{ selectedItems, setSelectedItems }}>
            <GameBasePage
                withArrows
                onLeftArrowClick={goBack}
                onRightArrowClick={openNextModal}
                className={pageClassName}
                wrapperClassName={styles['Page_Wrapper']}
                rightArrowDisabled={!selectedItems.length}
            >
                { children }
                {
                    !withoutArrows && (
                        <div className={styles['Level_Arrows_Wrapper']}>
                            { isMobile && (
                                <MobileGameArrows
                                    rightButtonDisabled={!selectedItems.length}
                                    className={styles['Level_Arrows']}
                                    onLeftButtonClick={onLeftArrowClick}
                                    onRightButtonClick={openNextModal}
                                />
                            ) }
                        </div>
                    )
                }
            </GameBasePage>
        </LevelContext.Provider>
    )
}