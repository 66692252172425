import bridge from '@vkontakte/vk-bridge'

import stories from '../img/png/stories-share.png'



export function createMerchandiseGameStories() {
    console.log(stories)
    return bridge.send('VKWebAppShowStoryBox', {
        request_id: 1,
        background_type: 'image',
        locked: true,
        url: 'https://sun9-35.userapi.com/impg/IltT8C24yUSyCd6zHXJBRbGqhe_mQwRzv07SlA/etJw1M6xLQ8.jpg?size=1080x1920&quality=96&sign=7ef7fc3bc571c6120f4cc6cbc27f8e27&type=album',
        attachment: {
            text: 'go_to',
            type: 'url',
            url: `https://vk.com/app8142558`
        }
    })
}