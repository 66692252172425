import { CSSProperties, FC } from "react";
import styles from './Typography.module.css';

interface ITypographyProps {
    children: string;
    style?: CSSProperties;
}

export const Typography: FC<ITypographyProps> = ({children, style}) => {
    return <p style={style} className={styles['Typography']}>{children}</p>
}