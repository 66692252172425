import React, {FC, useEffect, useState} from 'react'
import {Loader, PageBase} from '../../components'
import classes from './PreviewBookPage.module.css'
import {useAtomValue} from '@mntm/precoil'
import {atomNotes} from '../../store'
import {fetchNotes} from '../../store/reducers/book'
import {PreviewBook} from '../../components/Book/PreviewBook/PreviewBook'

export const PreviewBookPage: FC = () => {
    const { isLoaded: isRequested, data } = useAtomValue(atomNotes)
    const isLoaded = isRequested && !!data?.length

    useEffect(() => {
        !isRequested && fetchNotes()
    }, [])

    return (
        <PageBase>
            <div id="new-year" className={classes.content}>
                {!isLoaded
                    ? <Loader />
                    : <PreviewBook />
                }
            </div>
        </PageBase>
    )
}