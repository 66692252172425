import React, {FC} from 'react'

export const RedLogo: FC = () => (
    <svg width="104" height="65" viewBox="0 0 104 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_316_140)">
            <path d="M104 0H0V65H104V0Z" fill="#E21A1A"/>
            <path d="M67.2272 19.1229V20.3027H77.2358C77.8979 20.3027 78.9026 20.3027 79.5725 20.9811C80.2423 21.6595 80.2365 22.6741 80.2365 23.3545V33.5107C80.2365 34.1812 80.2364 35.2057 79.5725 35.8703C78.9085 36.535 77.9037 36.5507 77.2358 36.5507H72.4009C71.5656 36.5507 70.481 36.5507 70.0643 35.6167C69.6476 34.6827 70.2298 33.847 70.7322 33.1627L77.2339 24.3691H67.2253L63.2296 29.7805C62.5637 30.689 61.9814 31.4716 61.9814 32.4862C61.9814 33.5009 62.5169 34.2383 63.2296 35.1998L64.2344 36.5507C65.2274 37.9016 66.2302 39.2584 67.5621 39.9368C68.894 40.6152 70.4829 40.6112 72.6482 40.6112H76.9827C79.4809 40.6112 83.2351 40.6112 85.7334 38.0746C88.2317 35.538 88.2297 31.8196 88.2297 30.4609V26.3984C88.2297 25.0475 88.2297 21.3212 85.7295 18.7866C83.2293 16.252 79.4829 16.25 76.9846 16.25H70.0662C69.4003 16.25 68.5669 16.25 67.9029 16.9205C67.2389 17.5911 67.2272 18.4425 67.2272 19.1229Z" fill="white"/>
            <path d="M55.2301 24.377H65.229L53.2303 40.623H43.2314L55.2301 24.377Z" fill="white"/>
            <path d="M16.2314 27.2439C16.2314 26.5714 16.2314 25.7239 16.8993 25.0475C17.5672 24.3711 18.3967 24.377 19.0646 24.377H35.8105C37.9758 24.377 39.5628 24.377 40.8947 25.0475C42.2266 25.718 43.2313 27.0787 44.2322 28.4296L45.2272 29.7884C45.936 30.7499 46.4793 31.4794 46.4793 32.4941C46.4793 33.5087 45.8951 34.2992 45.2272 35.2077L41.2316 40.6329H31.2307L37.7305 31.8295C38.2329 31.155 38.8151 30.3075 38.4003 29.3715C37.9856 28.4355 36.899 28.4395 36.0637 28.4395H28.2359V48.748H20.2329V28.4296H16.2314V27.2498" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_316_140">
                <rect width="104" height="65" fill="white"/>
            </clipPath>
        </defs>
    </svg>

)