import { FC } from 'react'
import {GameLevelArrowTypes} from "./gameLevelArrowLeft";

export const GameLevelArrowRight: FC<GameLevelArrowTypes> = ({...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="90"
        height="60"
        fill="none"
        viewBox="0 0 90 60"
        {...props}
    >
        <path
            fill="#fff"
            d="M89.5 25C89.5 11.469 78.531.5 65 .5H25C11.469.5.5 11.469.5 25v10C.5 48.531 11.469 59.5 25 59.5h40c13.531 0 24.5-10.969 24.5-24.5V25z"
        ></path>
        <path
            stroke="#000"
            strokeLinecap="round"
            d="M28.375 30h33.25M52.684 39.5l8.941-9.5M52.684 20.5l8.941 9.5"
        ></path>
        <path
            stroke="#000"
            d="M89.5 25C89.5 11.469 78.531.5 65 .5H25C11.469.5.5 11.469.5 25v10C.5 48.531 11.469 59.5 25 59.5h40c13.531 0 24.5-10.969 24.5-24.5V25z"
        ></path>
    </svg>
)