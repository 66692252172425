import {FC, HTMLAttributes} from 'react'
import styles from './StarsResult.module.css'
import {StarIcon} from "../../img/svg/star";
import {getClassNames} from "../../utils/classNames";

interface StarsResultProps extends HTMLAttributes<HTMLDivElement> {
    stars: number
    fillColor?: string
}

export const StarsResult: FC<StarsResultProps> = ({ stars, fillColor, className, ...restProps }) => {
    const elementClassName = getClassNames(
        styles['StarsResult_Element'],
        className
    )
    const isStarFilled = (starIndex: number) => stars >= starIndex ? fillColor : ''

    return (
        <div
            className={elementClassName}
            {...restProps}
        >
            <StarIcon fillColor={isStarFilled(1)} />
            <StarIcon fillColor={isStarFilled(2)} />
            <StarIcon fillColor={isStarFilled(3)} />
        </div>
    )
}