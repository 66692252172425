import {FC, HTMLAttributes} from 'react'
import styles from './Level.module.css'
import {getClassNames} from "../../utils/classNames";

interface LevelProps extends HTMLAttributes<HTMLDivElement> {
    levelsCount: number
    currentLevel: number
}

export const Level: FC<Partial<LevelProps>> = (props) => {
    const { levelsCount, currentLevel, className } = props
    const levelClassName = getClassNames(
        styles['Level_element'],
        className
    )

    return (
        <div className={levelClassName}>
            <div className={styles['Level_title']}>
                <h1>уровень</h1>
            </div>

            <div className={styles['Level_Counters']}>
                <div className={styles['Count_element']}>
                    <span>{currentLevel}</span>
                </div>
                <div className={styles['Count_element']}>
                    <span>{levelsCount}</span>
                </div>
            </div>
        </div>
    )
}