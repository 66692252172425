import { FC, ReactNode } from "react";
import styles from "./MiniButton.module.css";

interface IMiniButtonProps {
  children: string;
  icon: ReactNode;
  onClick?: () => void;
}

export const MiniButton: FC<IMiniButtonProps> = ({ children, icon, onClick }) => {
  return <div className={styles["Button"]} onClick={onClick}>
    {icon}
    {children}
  </div>
}