import React, {FC, useState} from 'react'
import classes from '../../../pages/PreviewBookPage/PreviewBookPage.module.css'
import {Button} from '../../Button'
import {useNavigate} from 'react-router-dom'
import {Loader} from "../../Loader"
import {useAtomValue} from "@mntm/precoil"
import {atomNotes} from "../../../store"
import {PreviewBookImage} from "../../../img/svg/previewBook";

export const PreviewBook: FC = () => {
    const { isLoaded: isNotesLoaded } = useAtomValue(atomNotes)
    const navigate = useNavigate()
    const [bookLoaded, setBookLoaded] = useState(false)

    const openBook = () => {
        navigate('/book-expanded')
    }

    const onImageLoad = () => setBookLoaded(true)

    return (
        <>
            <div className={classes.Book}>
                <PreviewBookImage />
                <Button
                    size="s"
                    onClick={openBook}
                    className="new-year_button"
                >
                    Читать
                </Button>
            </div>
        </>
    )
}