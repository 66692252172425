import {FC, MouseEventHandler} from 'react'
import styles from './GameFinish.module.css'
import {Button} from "../../components";
import {closeModal} from "../../store/atoms/modal";

export interface GameFinishModalProps {
    title: string
    buttonText: string[]
    isNextButtonRed?: boolean
    onNextButtonClick?: MouseEventHandler<HTMLButtonElement>
}

export const GameFinishModal: FC<GameFinishModalProps> = (props) => {
    const { onNextButtonClick, title, isNextButtonRed, buttonText } = props
    const [ nextButtonText, waitButtonText ] = buttonText

    return (
        <div className={styles['GameFinish_Modal']}>
            <div className={styles['Modal_Text']}>
                <h1>{ title }</h1>
            </div>

            <div className={styles['Modal_Actions']}>
                <Button
                    size="l"
                    mode="gray"
                    style={{ background: isNextButtonRed ? '#EE3326' : '' }}
                    onClick={onNextButtonClick}
                >
                    { nextButtonText }
                </Button>
                <Button
                    size="l"
                    mode="gray"
                    onClick={closeModal}
                >
                    { waitButtonText }
                </Button>
            </div>
        </div>
    )
}