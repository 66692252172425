import {findItemsGameAPI} from "../../api/find-items";
import {
    atomMerchandiseGameInfo,
    setAtomMerchandiseGameInfo,
    setAtomMerchandiseGameResult
} from "../atoms/merchandiseGame";

export async function fetchFindItemGameInitMethod() {
    return new Promise(async(resolve) => {
        const response = await findItemsGameAPI.init<{ nextLevel: number, level: number | null, points: number }>()
        console.log(response)
        setAtomMerchandiseGameInfo({
            ...atomMerchandiseGameInfo.get(),
            level: response.nextLevel || 1,
            isAllLevelsPassed: !response.nextLevel
        })
        setAtomMerchandiseGameResult({ points: response.points })
        resolve(true)
    })
}