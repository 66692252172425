import bridge from '@vkontakte/vk-bridge'


export async function get(paramName: string, isMockup = false, mockupData?: string) {
    if (isMockup) return mockupData || ''
    const { keys } = await bridge.send('VKWebAppStorageGet',
        { keys: [paramName] }
    )
    return keys[0].value
}

export async function set(key: string, value: string) {
    return bridge.send('VKWebAppStorageSet', { key, value })
}

export const storage = { get, set }