import React, {FC} from 'react'
import {PageBase} from '../../components'
import styles from './MenuSelectionPage.module.css'
import {useNavigate} from 'react-router-dom'
import {SignButton} from '../../components/SignButton'
import sign from '../../img/png/sign.png'
import generateJourneyCard from '../../img/main-card/card-1.png'
import newYearDiaryCard from '../../img/main-card/card-2.png'
import merchandiseGameCard from '../../img/main-card/card-3.png'
import bridge from '@vkontakte/vk-bridge'
import {RedLogo} from "../../img/svg/redLogo";
import {RoutesPath} from "../../App";

export const MenuSelectionPage: FC = () => {
    const navigate = useNavigate()

    const goToJourney = () => navigate('../journey')
    const goToNewYearPage = () => {
        bridge.send('VKWebAppAllowMessagesFromGroup', { group_id: 38981315 })
        navigate(`../book-preview${window.location.hash}`)
    }
    // const goToMerchandiseGamePage = () => {
    //     navigate(`../${RoutesPath.MERCHANDISE_PRELOAD_PAGE}`)
    // }

    return (
        <PageBase>
            {/*<div className={styles['MenuSelectionPage_content']}>*/}
            {/*    <RedLogo />*/}

            {/*    <div className={styles['MenuSelectionPage_cards']}>*/}
            {/*        <MenuCard*/}
            {/*            title={`Генератор <br />путешествий`}*/}
            {/*            image={generateJourneyCard}*/}
            {/*        />*/}
            {/*        <MenuCard*/}
            {/*            className={styles['NewYearCard_content']}*/}
            {/*            title={`Дневник <br /> путешествий Деда <br /> Мороза`}*/}
            {/*            image={newYearDiaryCard}*/}
            {/*        />*/}
            {/*        <MenuCard*/}
            {/*            className={styles['Merchandise_content']}*/}
            {/*            title={`Спецпроект <br /> к выходу мерча`}*/}
            {/*            image={merchandiseGameCard}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={styles.content}>
                <RedLogo />

                <div className={styles.Buttons}>
                    <SignButton
                        arrowPosition="right"
                        onClick={goToJourney}
                    >
                        От Калининграда до Владивостока
                    </SignButton>
                    <SignButton
                        arrowPosition="left"
                        onClick={goToNewYearPage}
                    >
                        Дневник путешествий Деда Мороза
                    </SignButton>
                    {/* <SignButton
                        className={styles['Third_Sign']}
                        arrowPosition="left"
                        onClick={goToMerchandiseGamePage}
                    >
                        Внимательный пассажир
                    </SignButton> */}
                    <img className={styles.Sign} src={sign} alt=""/>
                </div>
            </div>
        </PageBase>
    )
}