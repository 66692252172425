import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Heading, PageBase } from "../../components";
import { choiceCard } from "../../utils/choiceCards";
import styles from "./ChoicePage.module.css";
import { useDebounce } from '../../hooks/useDebounce';

export const ChoicePage = () => {
  const firstContainer = useRef<HTMLDivElement>(null);
  const secondContainer = useRef<HTMLDivElement>(null);
  const thirdContainer = useRef<HTMLDivElement>(null);
  const [error, setError] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [scrollFirstBack, setScrollFirstBack] = useState(false);
  const [scrollSecondBack, setScrollSecondBack] = useState(false);
  const [scrollThirdBack, setScrollThirdBack] = useState(false);

  const [firstCategory, setFirstCategory] = useState<
    "calm" | "active" | "culture"
  >();
  const [secondCategory, setSecondCategory] = useState<
    "single" | "company" | "family"
  >();
  const [thirdCategory, setThirdCategory] = useState<
    "smallBudget" | "averageBudget" | "unlimitedBudget"
  >();

  const navigation = useNavigate();

  const isButtonActive = firstCategory && secondCategory && thirdCategory;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    function scroll() {
      if (firstContainer.current && !isClicked) {
        if (firstContainer.current.scrollLeft < firstContainer.current.scrollWidth - firstContainer.current.clientWidth && !scrollFirstBack) {
          firstContainer.current.scrollLeft += 1;
          timeout = setTimeout(scroll, 16);
        } else if (scrollFirstBack) {
          if (!firstContainer.current.scrollLeft) setScrollFirstBack(false);
          firstContainer.current.scrollLeft -= 1;
          timeout = setTimeout(scroll, 16);
        } else {
          setScrollFirstBack(true);
        }
      }
    }
    scroll();

    return () => clearInterval(timeout);

  }, [isClicked, scrollFirstBack]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    function scroll() {
      if (secondContainer.current && !isClicked) {
        if (secondContainer.current.scrollLeft < secondContainer.current.scrollWidth - secondContainer.current.clientWidth && !scrollSecondBack) {
          secondContainer.current.scrollLeft += 1;
          timeout = setTimeout(scroll, 16);
        } else if (scrollSecondBack) {
          if (!secondContainer.current.scrollLeft) setScrollSecondBack(false);
          secondContainer.current.scrollLeft -= 1;
          timeout = setTimeout(scroll, 16);
        } else {
          setScrollSecondBack(true);
        }
      }
    }
    scroll();

    return () => clearInterval(timeout);

  }, [isClicked, scrollSecondBack]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    function scroll() {
      if (thirdContainer.current && !isClicked) {
        if (thirdContainer.current.scrollLeft < thirdContainer.current.scrollWidth - thirdContainer.current.clientWidth && !scrollThirdBack) {
          thirdContainer.current.scrollLeft += 1;
          timeout = setTimeout(scroll, 16);
        } else if (scrollThirdBack) {
          if (!thirdContainer.current.scrollLeft) setScrollThirdBack(false);
          thirdContainer.current.scrollLeft -= 1;
          timeout = setTimeout(scroll, 16);
        } else {
          setScrollThirdBack(true);
        }
      }
    }
    scroll();

    return () => clearInterval(timeout);

  }, [isClicked, scrollThirdBack]);

  const handleClick = () => {
    if (!(firstCategory && secondCategory && thirdCategory)) setError(true);
    else {
      const data = choiceCard([firstCategory, secondCategory, thirdCategory]);
      console.log(data);
      navigation("/result", { state: data });
    }
  };

  const { debounce } = useDebounce();

  const handleScroll = (): void => {
    if(isClicked) debounce(() => setIsClicked(false), 1000);
  }

  // useEffect(() => {
  //   window.addEventListener("pointerup", () => setIsClicked(false));
  // }, []);

  return (
    <PageBase>
      <div className={styles["Page__content"]}>
        <div className={styles["Page__background"]}>
          <div className={styles["Content"]}>
            <Heading className={styles["Heading"]}>
              Моё идеальное путешествие&nbsp;выглядит так:
            </Heading>
            <div className={styles["Buttons__container"]} ref={firstContainer} onPointerDown={() => { setIsClicked(true) }} onScroll={handleScroll}>
              <Button
                size="s"
                active={firstCategory === "calm"}
                onClick={() => setFirstCategory("calm")}
              >
                Спокойный отдых
              </Button>
              <Button
                size="s"
                active={firstCategory === "culture"}
                onClick={() => setFirstCategory("culture")}
              >
                Культурный отдых
              </Button>
              <Button
                size="s"
                active={firstCategory === "active"}
                onClick={() => setFirstCategory("active")}
              >
                Активный отдых
              </Button>
            </div>
            <div className={styles["Buttons__container"]} ref={secondContainer} onPointerDown={() => { setIsClicked(true) }} onScroll={handleScroll}>
              <Button
                size="s"
                active={secondCategory === "single"}
                onClick={() => setSecondCategory("single")}
              >
                В одиночестве
              </Button>
              <Button
                size="s"
                active={secondCategory === "company"}
                onClick={() => setSecondCategory("company")}
              >
                В компании
              </Button>
              <Button
                size="s"
                active={secondCategory === "family"}
                onClick={() => setSecondCategory("family")}
              >
                С семьёй
              </Button>
            </div>
            <div className={styles["Buttons__container"]} ref={thirdContainer} onPointerDown={() => { setIsClicked(true) }} onScroll={handleScroll}>
              <Button
                size="s"
                active={thirdCategory === "smallBudget"}
                onClick={() => setThirdCategory("smallBudget")}
              >
                С небольшим бюджетом
              </Button>
              <Button
                size="s"
                active={thirdCategory === "averageBudget"}
                onClick={() => setThirdCategory("averageBudget")}
              >
                Со средним бюджетом
              </Button>
              <Button
                size="s"
                active={thirdCategory === "unlimitedBudget"}
                onClick={() => setThirdCategory("unlimitedBudget")}
              >
                С неограниченным бюджетом
              </Button>
            </div>
            <Button
              size="m"
              style={{ marginTop: 23 }}
              disabled={!isButtonActive}
              onClick={handleClick}
            >
              Посмотреть предложения
            </Button>
            {error && (
              <p className={styles["Error"]}>
                Не смогли подобрать варианты.{`\n`} Пожалуйста, выберите один
                вариант в каждой категории поездки.
              </p>
            )}
          </div>
        </div>
      </div>
    </PageBase>
  );
};
