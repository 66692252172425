import React, {FC} from 'react'
import {ArrowLeft} from "../../img/svg/arrowLeft";
import classes from "../../pages/ExpandedBookPage/ExpandedBookPage.module.css";
import {ArrowRight} from "../../img/svg/arrowRight";
import {useAtomValue} from "@mntm/precoil";
import {atomNotes} from "../../store";

interface props {
    noteIndex: number
    toPrevPage: () => void
    toNextPage: () => void
}

export const Arrows: FC<props> = ({noteIndex, toNextPage, toPrevPage}) => {
    const { data: notes } = useAtomValue(atomNotes)

    return (
        <>
            {
                noteIndex > 0 && (
                    <ArrowLeft
                        className={`${classes.Arrow} ${classes.Left} clickable`}
                        onClick={toPrevPage}
                    />
                )
            }

            {
                noteIndex + 1 < notes.length && (
                    <ArrowRight
                        className={`${classes.Arrow} ${classes.Right} clickable`}
                        onClick={toNextPage}
                    />
                )
            }
        </>
    )
}