import {getUrlParams} from "./url";

export function getMobileOperatingSystem() {
    const vk_platform = getUrlParams('vk_platform')
    const isVKiOS = vk_platform === 'mobile_ipad' || vk_platform === 'mobile_iphone' || vk_platform === 'mobile_iphone_messenger'
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window?.opera;

    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
        return 'Android'
    }

    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) || isVKiOS) {
        return 'iOS'
    }

    return 'unknown'
}