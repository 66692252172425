import { FC } from 'react'
import {Button, GameBasePage, Placeholder} from "../../../components";
import icon from '../../../img/png/start-screen-illustration.png'
import styles from './StartPage.module.css'
import {useNavigate} from "react-router-dom";
import {RoutesPath} from "../../../App";
import {StartPageIllustration} from "../../../img/svg/start-page-illustration";

export const StartPage: FC = () => {
    const navigate = useNavigate()

    const goToSelectLevelPage = () => navigate(`../${RoutesPath.MERCHANDISE_LEVELS_PAGE}`)
    const goToMenu = () => navigate(`../${RoutesPath.MAIN_PAGE}`, { replace: true })

    return (
        <GameBasePage
            className={styles['StartPage_content']}
        >
            <Placeholder
                className={styles['StartPage_Placeholder']}
                icon={<img src={icon} className={styles['Placeholder_icon']} />}
                title="Внимательный пассажир"
                description={`Всё просто: пройдите пять уровней и&nbsp;найдите отличающиеся предметы.\nВысокие баллы за&nbsp;прохождение игры откроют вам доступ к&nbsp;розыгрышу от&nbsp;РЖД`}
                action={
                    <div className={styles['StartPage_Placeholder_action']}>
                        <Button
                            size="l"
                            mode="gray"
                            onClick={goToSelectLevelPage}
                        >
                            начать игру
                        </Button>
                        <Button
                            size="l"
                            mode="gray"
                            onClick={goToMenu}
                        >
                            на главную
                        </Button>
                    </div>
                }
            />
        </GameBasePage>
    )
}