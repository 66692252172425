import React, {FC, HTMLAttributes, ReactNode} from 'react'
import classes from './ShareButton.module.css'

interface props extends HTMLAttributes<HTMLDivElement | HTMLButtonElement> {
    icon: ReactNode
}

export const ButtonShare: FC<props> = (props) => {
    const { icon, children, ...restProps } = props

    return (
        <div
            className={classes.Share_Button}
            {...restProps}
        >
            {icon}
            <span>{children}</span>
        </div>
    )
}