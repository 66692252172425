import {FC, useContext} from 'react'
import {BaseLevelPage} from "../../BaseLevelPage";
import {useNavigate} from "react-router-dom";
import {ThirdBaseItem} from "../../../../img/svg/merchandise-levels/third-item";
import styles from './ThirdLevelPage.module.css'
import {BaseItem} from "../../../../img/svg/merchandise-levels/base-item";
import {ThirdItemBaseColor} from "../../../../img/svg/merchandise-levels/third-level/item-base-color";
import {ThirdItemWithoutHoles} from "../../../../img/svg/merchandise-levels/third-level/item-without-holes";
import {ThirdItemWithCrack} from "../../../../img/svg/merchandise-levels/third-level/item-with-crack";
import {ThirdItemWithWater} from "../../../../img/svg/merchandise-levels/third-level/item-with-water";
import {ThirdItemWithoutHandle} from "../../../../img/svg/merchandise-levels/third-level/item-without-handle";
import {ThirdItemWithWrongHandle} from "../../../../img/svg/merchandise-levels/third-level/item-with-wrong-handle";
import {ThirdItemWithWrongColor} from "../../../../img/svg/merchandise-levels/third-level/item-with-wrong-color";
import {
    ThirdLevelItemBaseColorSelected
} from "../../../../img/svg/merchandise-levels/third-level/item-base-color-selected";
import {
    ThirdLevelItemWithoutHolesSelected
} from "../../../../img/svg/merchandise-levels/third-level/item-without-holes-selected";
import {
    ThirdLevelItemWithCrackSelected
} from "../../../../img/svg/merchandise-levels/third-level/item-with-crack-selected";
import {
    ThirdLevelItemWithWaterSelected
} from "../../../../img/svg/merchandise-levels/third-level/item-with-water-selected";
import {
    ThirdLevelItemWithoutHandleSelected
} from "../../../../img/svg/merchandise-levels/third-level/item-without-handle-selected";
import {
    ThirdItemWithWrongHandleSelected
} from "../../../../img/svg/merchandise-levels/third-level/item-with-wrong-handle-selected";
import {
    ThirdLevelItemWithWrongColorSelected
} from "../../../../img/svg/merchandise-levels/third-level/item-with-wrong-color-selected";
export const ThirdLevelPage: FC = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1)
    return (
        <BaseLevelPage
            className={styles['Level_Page']}
            nextModalTitle="Готовы к 4-му уровню?"
            nextModalButtonsText={['Да, я собран как никогда', 'Нет, ещё поищу здесь']}
            level={3}
            onLeftArrowClick={goBack}
        >
            <div className={styles['Level_Lines']}>
                <div className={styles['Cup_Line1']}>
                    <BaseItem index={1}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={2}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={3}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={4}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={5}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={6}><ThirdBaseItem /></BaseItem>
                </div>
                <div className={styles['Cup_Line2']}>
                    <BaseItem index={7}><ThirdBaseItem /></BaseItem>
                    <BaseItem
                        selectedItem={<ThirdLevelItemBaseColorSelected />}
                        index={8}
                    >
                        <ThirdItemBaseColor />
                    </BaseItem>
                    <BaseItem index={9}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={10}><ThirdBaseItem /></BaseItem>
                    <BaseItem
                        selectedItem={<ThirdLevelItemWithoutHolesSelected />}
                        index={11}
                    >
                        <ThirdItemWithoutHoles />
                    </BaseItem>
                    <BaseItem index={12}><ThirdBaseItem /></BaseItem>
                </div>
                <div className={styles['Cup_Line3']}>
                    <BaseItem index={13}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={14}><ThirdBaseItem /></BaseItem>
                    <BaseItem
                        selectedItem={<ThirdLevelItemWithCrackSelected />}
                        index={15}
                    >
                        <ThirdItemWithCrack />
                    </BaseItem>
                    <BaseItem index={16}><ThirdBaseItem /></BaseItem>
                    <BaseItem
                        selectedItem={<ThirdLevelItemWithWaterSelected />}
                        index={17}
                    >
                        <ThirdItemWithWater />
                    </BaseItem>
                    <BaseItem index={18}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={99}><ThirdBaseItem /></BaseItem>
                </div>

                <div className={styles['Cup_Line4']}>
                    <BaseItem index={19}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={20}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={21}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={22}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={23}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={24}><ThirdBaseItem /></BaseItem>
                </div>

                <div className={styles['Cup_Line5']}>
                    <BaseItem index={25}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={26}><ThirdBaseItem /></BaseItem>
                    <BaseItem
                        selectedItem={<ThirdLevelItemWithoutHandleSelected />}
                        index={27}
                    >
                        <ThirdItemWithoutHandle />
                    </BaseItem>
                    <BaseItem index={28}><ThirdBaseItem /></BaseItem>
                    <BaseItem
                        selectedItem={<ThirdItemWithWrongHandleSelected />}
                        index={29}
                    >
                        <ThirdItemWithWrongHandle />
                    </BaseItem>
                    <BaseItem index={30}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={100}><ThirdBaseItem /></BaseItem>
                </div>

                <div className={styles['Cup_Line6']}>
                    <BaseItem index={31}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={32}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={33}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={34}><ThirdBaseItem /></BaseItem>
                    <BaseItem
                        selectedItem={<ThirdLevelItemWithWrongColorSelected />}
                        index={35}
                    >
                        <ThirdItemWithWrongColor />
                    </BaseItem>
                    <BaseItem index={36}><ThirdBaseItem /></BaseItem>
                </div>


                <div className={styles['Cup_Line5']}>
                    <BaseItem index={37}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={38}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={39}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={40}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={41}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={42}><ThirdBaseItem /></BaseItem>
                </div>


                <div className={styles['Cup_Line6']}>
                    <BaseItem index={43}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={44}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={45}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={46}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={47}><ThirdBaseItem /></BaseItem>
                    <BaseItem index={48}><ThirdBaseItem /></BaseItem>
                </div>
            </div>
        </BaseLevelPage>
    )
}