import React, {FC} from 'react'
import Lottie from 'lottie-react'
import lottie from '../../img/lottie/preloader.json'
import classes from './Loader.module.css'

export const Loader: FC = () => (
    <Lottie
        className={classes.Loader}
        animationData={lottie}
        loop
    />
)