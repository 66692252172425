import { CSSProperties, FC } from "react";

interface IArrowRight {
  style?: CSSProperties;
  onClick?: () => void;
  className?: string
}

export const ArrowRight: FC<IArrowRight> = ({ style, onClick, className }) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      className={className}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="17" fill="white" />
      <path
        d="M14.3536 8.64645C14.1583 8.45118 13.8417 8.45118 13.6464 8.64645C13.4512 8.84171 13.4512 9.15829 13.6464 9.35355L14.3536 8.64645ZM22 17L22.3536 17.3536L22.7071 17L22.3536 16.6464L22 17ZM13.6464 24.6464C13.4512 24.8417 13.4512 25.1583 13.6464 25.3536C13.8417 25.5488 14.1583 25.5488 14.3536 25.3536L13.6464 24.6464ZM13.6464 9.35355L21.6464 17.3536L22.3536 16.6464L14.3536 8.64645L13.6464 9.35355ZM21.6464 16.6464L13.6464 24.6464L14.3536 25.3536L22.3536 17.3536L21.6464 16.6464Z"
        fill="#1F4F69"
      />
    </svg>
  );
};
