import {atom} from "@mntm/precoil";
import {ReactNode} from "react";

type AtomModalType = {
    isOpen: boolean
    children: ReactNode
}
export const atomModal = atom<AtomModalType>({isOpen: false, children: null}, 'modal')
export const setModal = (children: ReactNode) => atomModal.set({
    isOpen: !!children,
    children
})
export const closeModal = () => setModal(null)