import React, {FC, useState} from 'react'
import classes from "../../pages/ExpandedBookPage/ExpandedBookPage.module.css";

export const Illustration: FC<{src: string, index: number}> = ({ src, index}) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false)

    return (
        <div
            key={index}
            className={classes.Book_Illustration}
            style={{margin: !index ? '30px 85px' : '30px 80px 0 0'}}
        >
            {/*{!isImageLoaded && <div style={{width: 350, height: 350}} />}*/}
            <img
                onLoad={() => setIsImageLoaded(true)}
                src={src}
                alt=''
            />
        </div>
    )
}