import { FC } from 'react'
import styles from './LevelResultPage.module.css'
import {Button, GameBasePage, Level, StarsResult} from "../../../components";
import {useAtomValue} from "@mntm/precoil";
import {atomMerchandiseGameInfo, setAtomMerchandiseGameInfo} from "../../../store/atoms/merchandiseGame";
import {declination} from "../../../utils/text";
import {useNavigate} from "react-router-dom";
import {RoutesPath} from "../../../App";
import {getClassNames} from "../../../utils/classNames";
import {RetryIcon} from "../../../img/svg/retryIcon";
import {findItemsGameAPI} from "../../../api/find-items";

const levelsTitleCopy: { [key: number]: string } = {
    1: 'САМОЕ НАЧАЛО',
    2: 'Продвинулись вперёд',
    3: 'Перешагнули половину',
    4: 'Финишная прямая',
    5: 'Подводим итоги',
}

const levelsResultCopy: { [level: number]: { [stars: number]: string } } = {
    1: {
        1: 'Будьте внимательнее — и всё получится!',
        2: 'Неплохо, настройте внимательность на максимум!',
        3: 'Хороший старт!'
    },
    2: {
        1: 'Будьте внимательнее — и всё получится!',
        2: 'Неплохо, настройте внимательность на максимум!',
        3: 'Так держать!'
    },
    3: {
        1: 'Будьте внимательнее — и всё получится!',
        2: 'Неплохо, настройте внимательность на максимум!',
        3: 'Так держать!'
    },
    4: {
        1: 'Будьте внимательнее — и всё получится!',
        2: 'Неплохо, настройте внимательность на максимум!',
        3: 'Так держать!'
    },
    5: {
        1: 'Будьте внимательнее — и всё получится!',
        2: 'Неплохо, настройте внимательность на максимум!',
        3: 'Так держать!'
    },
}

export const LevelResultPage: FC = () => {
    const navigate = useNavigate()
    const { level, points, stars, backgroundColor, componentColor } = useAtomValue(atomMerchandiseGameInfo)
    const btnClassName = styles[`Button_element--level-${level}`]
    const retryLevel = () => navigate(`../merchandise-${level}-level`, { replace: true })
    const goToNextLevel = () => {
        if (level >= 5) return navigate(`..${RoutesPath.MERCHANDISE_GAME_RESULT_PAGE}`, { replace: true })
        setAtomMerchandiseGameInfo({
            ...atomMerchandiseGameInfo.get(),
            level: level + 1
        })
        navigate(`../${RoutesPath.MERCHANDISE_LEVELS_PAGE}`, {replace: true})
    }

    const goToMenu = () => {
        findItemsGameAPI.resetProgress()

        setAtomMerchandiseGameInfo({
            ...atomMerchandiseGameInfo.get(),
            level: 1
        })
        navigate(`..${RoutesPath.MAIN_PAGE}`, {replace: true})
    }

    return (
        <GameBasePage
            className={styles['Result_Page']}
            wrapperClassName={styles['Result_Page_Wrapper']}
            style={{ background: backgroundColor }}
        >
            <Level
                levelsCount={5}
                currentLevel={level}
                className={styles['Level_element']}
            />

            <div className={styles['Page_Content']}>
                <StarsResult
                    className={styles['Stars_Element']}
                    fillColor={componentColor}
                    stars={stars}
                />

                <div className={styles['Page_Text']}>
                    <div className={styles['Page_Title']}>
                        <h1>{ levelsTitleCopy[level] }</h1>
                    </div>
                    <div className={styles['Page_Description']}>
                        <h2>Вы набрали { points } { declination(points, ['балл', 'балла', 'баллов']) }.</h2>
                        <h2>{ levelsResultCopy[level][stars] }</h2>
                    </div>
                </div>
            </div>

            <div className={styles['Page_Actions']}>
                <Button
                    size="l"
                    mode="gray"
                    className={getClassNames(btnClassName, styles['Retry_Icon'])}
                    onClick={retryLevel}
                >
                    <RetryIcon />
                </Button>
                <Button
                    size="l"
                    mode="gray"
                    className={btnClassName}
                    onClick={goToNextLevel}
                >
                    {
                        level >= 5
                            ? 'закончить игру'
                            : 'следующий уровень'
                    }
                </Button>
                <Button
                    size="l"
                    mode="gray"
                    className={btnClassName}
                    onClick={goToMenu}
                >
                    в главное меню
                </Button>
            </div>
        </GameBasePage>
    )
}