import { FC } from 'react'

export const RetryIcon: FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="24"
        fill="none"
        viewBox="0 0 26 24"
    >
        <path
            fill="#000"
            d="M18.857 1.141C15.847-.216 12.514-.372 9.395.724a12.035 12.035 0 00-6.827 5.95l-1.56-3.967a.542.542 0 00-.698-.313c-.269.104-.376.417-.269.678L2.084 8.24c.108.209.269.313.484.313h.107l5.591-1.253c.27-.052.484-.365.377-.626-.054-.26-.377-.47-.645-.365l-4.57 1.044c2.634-5.48 9.354-7.829 15-5.271 5.644 2.557 8.117 9.08 5.483 14.56-2.635 5.48-9.408 7.829-15.053 5.271-2.366-1.095-4.301-2.922-5.43-5.218-.107-.261-.43-.366-.699-.261-.269.104-.376.417-.269.678 1.237 2.505 3.333 4.54 5.914 5.74A12.212 12.212 0 0013.589 24c4.677 0 9.139-2.557 11.236-6.941 1.397-2.923 1.559-6.159.43-9.185-1.13-2.975-3.387-5.376-6.398-6.733z"
        ></path>
    </svg>
)