import React, {FC, HTMLAttributes, MouseEventHandler} from 'react'

interface MobileGameArrowsProps extends HTMLAttributes<HTMLOrSVGElement> {
    leftButtonDisabled?: boolean
    rightButtonDisabled?: boolean
    onLeftButtonClick?: MouseEventHandler<HTMLOrSVGElement>
    onRightButtonClick?: MouseEventHandler<HTMLOrSVGElement>
}

export const MobileGameArrows: FC<MobileGameArrowsProps> = (props) => {
    const { leftButtonDisabled, rightButtonDisabled, onRightButtonClick, onLeftButtonClick, ...restProps } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="179"
            height="60"
            fill="none"
            viewBox="0 0 179 60"
            {...restProps}
        >
            <g
                opacity="0.9"
                className="GameArrow_Mobile"
                onClick={e => !leftButtonDisabled && onLeftButtonClick && onLeftButtonClick(e)}
            >
                <path
                    style={{ fill: leftButtonDisabled ? '#D1D3D4' : '' }}
                    fill="#fff"
                    d="M.5 25C.5 11.469 11.469.5 25 .5h64.5v59H25C11.469 59.5.5 48.531.5 35V25z"
                ></path>
                <path
                    style={{ opacity: leftButtonDisabled ? 0.1 : 1 }}
                    stroke="#000"
                    strokeLinecap="round"
                    className="GameArrow_Element"
                    d="M61.625 30h-33.25M37.316 39.5L28.375 30M37.316 20.5L28.375 30"
                ></path>
                <path
                    style={{ stroke: leftButtonDisabled ? 'none' : '#000' }}
                    stroke="#000"
                    d="M.5 25C.5 11.469 11.469.5 25 .5h64.5v59H25C11.469 59.5.5 48.531.5 35V25z"
                ></path>
            </g>
            <g
                opacity="0.9"
                className="GameArrow_Mobile"
                style={{ background: rightButtonDisabled ? '#D1D3D4' : '' }}
                onClick={e => !rightButtonDisabled && onRightButtonClick && onRightButtonClick(e)}
            >
                <path
                    style={{ fill: rightButtonDisabled ? '#D1D3D4' : '' }}
                    fill="#fff"
                    d="M89.5.5H154c13.531 0 24.5 10.969 24.5 24.5v10c0 13.531-10.969 24.5-24.5 24.5H89.5V.5z"
                ></path>
                <path
                    style={{ opacity: rightButtonDisabled ? 0.1 : 1 }}
                    stroke="#000"
                    strokeLinecap="round"
                    className="GameArrow_Element"
                    d="M117.375 30h33.25M141.684 20.5l8.941 9.5M141.684 39.5l8.941-9.5"
                ></path>
                <path
                    style={{ stroke: rightButtonDisabled ? 'none' : '#000' }}
                    stroke="#000"
                    d="M89.5.5H154c13.531 0 24.5 10.969 24.5 24.5v10c0 13.531-10.969 24.5-24.5 24.5H89.5V.5z"
                ></path>
            </g>
        </svg>
    )
}