import {FC, ReactNode, useState} from 'react'
import {Button, GameBasePage, Level, Placeholder} from '../../../components'
import styles from './SelectLevel.module.css'

import { CSSTransition } from 'react-transition-group'
import {getClassNames} from "../../../utils/classNames";
import {useNavigate} from "react-router-dom"
import {atomMerchandiseGameInfo, setAtomMerchandiseGameInfo} from "../../../store/atoms/merchandiseGame";
import {useAtomValue} from "@mntm/precoil";
import {SuitcaseLevelIllustration} from "../../../img/svg/suitcase-level-illustration";
import {BagLevelIllustration} from "../../../img/svg/bag-level-illustration";
import {CupLevelIllustration} from "../../../img/svg/cup-level-illustration";
import {SleepMaskLevelIllustration} from "../../../img/svg/sleep-mask-level-illustration";
import {PenLevelIllustration} from "../../../img/svg/pen-level-illustration";

export const levelsCopy: { [key: number]: { image: ReactNode, text: string, buttonColor: string, backgroundColor: string } } = {
    0: {
        image: <SuitcaseLevelIllustration />,
        text: `\nЭто наш оригинальный чехол. \nОн&nbsp;сделает ваш чемодан модным и&nbsp;защитит от&nbsp;пыли \n\n <b>Найдите лишние предметы</b>`,
        buttonColor: '#6CA8DC',
        backgroundColor: '#B1D5F5'
    },
    1: {
        image: <BagLevelIllustration />,
        text: `\nЭто наша красная сумка-шопер. \nВ&nbsp;неё поместится всё необходимое для поездки \n\n <b>Найдите лишние предметы</b>`,
        buttonColor: '#FF9E97',
        backgroundColor: '#FFCFCC'
    },
    2: {
        image: <CupLevelIllustration style={{marginLeft: 25}} />,
        text: `\nДа&nbsp;это&nbsp;же тот самый подстаканник! \nГоворят, что он&nbsp;делает все напитки вкуснее \n\n <b>Найдите лишние предметы</b>`,
        buttonColor: '#A8A9AC',
        backgroundColor: '#D1D3D4'
    },
    3: {
        image: <SleepMaskLevelIllustration />,
        text: `\nЭто наша оригинальная маска для сна. \nОна бережно сохранит ваш сон во&nbsp;время поездки \n\n <b>Найдите лишние предметы</b>`,
        buttonColor: '#829AD6',
        backgroundColor: '#AFC2F1'
    },
    4: {
        image: <PenLevelIllustration />,
        text: `\nЭто наша шариковая ручка с&nbsp;важным наставлением. \nС&nbsp;ней ни&nbsp;одна гениальная идея не&nbsp;пропадёт \n\n <b>Найдите лишние предметы</b>`,
        buttonColor: '#D68776',
        backgroundColor: '#DDA295'
    }
}

export const SelectLevel: FC = () => {
    const navigate = useNavigate()
    const { level, isAllLevelsPassed } = useAtomValue(atomMerchandiseGameInfo)
    const [currentLevel, setCurrentLevel] = useState(level - 1)
    const { image, text, buttonColor, backgroundColor } = levelsCopy[currentLevel]
    const contentClassName = getClassNames(
        styles['SelectLevel_Content'],
        styles[`Placeholder--level-${currentLevel + 1}`]
    )

    const goToGame = () => {
        setAtomMerchandiseGameInfo({
            level: currentLevel + 1,
            componentColor: buttonColor,
            backgroundColor,
            stars: 0, points: 0
        })
        navigate(`../merchandise-${currentLevel + 1}-level`)
    }

    const goBack = () => {
        console.log(currentLevel)
        navigate(-1)
    }


    return (
        <GameBasePage
            withArrows
            className={contentClassName}
            wrapperClassName={styles['SelectLevel_Wrapper']}
            onRightArrowClick={() => setCurrentLevel(prev => prev + 1)}
            onLeftArrowClick={() => currentLevel <= 0 ? goBack() : setCurrentLevel(prev => prev - 1)}
            rightArrowDisabled
        >
            <Level
                levelsCount={5}
                currentLevel={currentLevel + 1}
                className={styles['Level_element']}
            />

            <CSSTransition
                key={currentLevel}
                timeout={500}
                classNames="fade"
            >
                <div style={{ height: '100%' }}>
                    <Placeholder
                        className={styles['Placeholder_element']}
                        actionClassName={styles['Placeholder_action']}
                        iconClassName={styles['Placeholder_icon']}
                        icon={image}
                        description={text}
                        action={
                            <Button
                                size="l"
                                mode="gray"
                                className={getClassNames(
                                    styles['Button_element'],
                                    styles[`Button_element--level-${currentLevel + 1}`]
                                )}
                                onClick={goToGame}
                            >
                                Открыть игру
                            </Button>
                        }
                    />
                </div>
            </CSSTransition>
        </GameBasePage>
    )
}