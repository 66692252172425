import { CSSProperties, FC } from "react";

interface IArrowLeft {
  style?: CSSProperties;
  onClick?: () => void;
  className?: string
}

export const ArrowLeft: FC<IArrowLeft> = ({ style, onClick, className }) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      className={className}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17"
        cy="17"
        r="17"
        transform="rotate(-180 17 17)"
        fill="white"
      />
      <path
        d="M19.6464 25.3536C19.8417 25.5488 20.1583 25.5488 20.3536 25.3536C20.5488 25.1583 20.5488 24.8417 20.3536 24.6464L19.6464 25.3536ZM12 17L11.6464 16.6464L11.2929 17L11.6464 17.3536L12 17ZM20.3536 9.35355C20.5488 9.15829 20.5488 8.84171 20.3536 8.64645C20.1583 8.45118 19.8417 8.45118 19.6464 8.64645L20.3536 9.35355ZM20.3536 24.6464L12.3536 16.6464L11.6464 17.3536L19.6464 25.3536L20.3536 24.6464ZM12.3536 17.3536L20.3536 9.35355L19.6464 8.64645L11.6464 16.6464L12.3536 17.3536Z"
        fill="#1F4F69"
      />
    </svg>
  );
};
