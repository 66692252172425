import React, {FC, HTMLAttributes} from 'react'
import classes from './SignButton.module.css'
import arrow from '../../img/svg/arrow.svg'

interface props extends HTMLAttributes<HTMLDivElement> {
    arrowPosition: 'left' | 'right'
}

export const SignButton: FC<props> = (props) => {
    const { arrowPosition, children, className: restClassName, ...restProps } = props
    const isLeftArrow = arrowPosition === 'left'
    const className = `${classes.Sign_Button} ${restClassName || ''}`

    return (
        <div
            className={className}
            {...restProps}
        >
            <div>
                {isLeftArrow ? <img className={classes.Left_Arrow} src={arrow} alt="" /> : <span></span>}
                <div
                    style={{marginLeft: isLeftArrow ? -60 : 60}}
                >
                    {children}
                </div>
                {!isLeftArrow ? <img src={arrow} alt="" /> : <span></span>}
            </div>
        </div>
    )
}