import {FC, HTMLAttributes} from 'react'

export type GameLevelArrowTypes = HTMLAttributes<HTMLOrSVGElement>
export const GameLevelArrowLeft: FC<GameLevelArrowTypes> = ({...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="90"
        height="60"
        fill="none"
        viewBox="0 0 90 60"
        {...props}
    >
        <path
            fill="#fff"
            d="M.5 25C.5 11.469 11.469.5 25 .5h40C78.531.5 89.5 11.469 89.5 25v10c0 13.531-10.969 24.5-24.5 24.5H25C11.469 59.5.5 48.531.5 35V25z"
        ></path>
        <path
            stroke="#000"
            strokeLinecap="round"
            d="M61.625 30h-33.25M37.316 39.5L28.375 30M37.316 20.5L28.375 30"
        ></path>
        <path
            stroke="#000"
            d="M.5 25C.5 11.469 11.469.5 25 .5h40C78.531.5 89.5 11.469 89.5 25v10c0 13.531-10.969 24.5-24.5 24.5H25C11.469 59.5.5 48.531.5 35V25z"
        ></path>
    </svg>
)